import axios from 'axios'
import TimecardDay from '@internal/models/TimecardDay'
import TimecardMonth from '@internal/models/TimecardMonth'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.head.querySelector('meta[name="api-token"]').content;
axios.defaults.baseURL = document.head.querySelector('meta[name="api-root"]').content;



export function daily(monthdate){
    return axios.get('/manager/timecard-daily/' + monthdate)
    .then(res => res.data)
    .then( data => data.map(timecard => new TimecardDay(timecard)))
}

export function monthly(id,monthdate){
  //    return axios.get('api/user')
    //  .then(res => res.data)
      //.then( data => TimecardDay.map(data))
      return axios.get('manager/timecard-month/'+ id + '/' + monthdate)
      .then(res => res.data)
      .then( data => new TimecardMonth(data))
  //    .then( data => data.map(timecard => new TimecardDay(timecard)))
}

export function save(user_id,payload){
  return axios.post('manager/timecard-application/' + user_id,payload);
}

export function del(id){
  return axios.post('manager/timecard-application/delete/'+ id);
}

export function getDatabyId(id){
    return axios.get('timecard-application/' + id)
    .then(res => res.data)
    .then( data  => new TimecardApplication(data))
}


  
export function get_closed(){
  return axios.get('timecard/closed')
    .then(res => String(res.data));
}
