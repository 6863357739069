<template>
<div id="app">
<v-app id="timecard">
    <v-data-table
    dense
    :headers="headers"
    :items="tableData"
    class="elevation-1"
    hide-default-footer 
    disable-pagination
    >

    <template v-slot:top>
        <v-toolbar
            flat
        >
        <v-toolbar-title>ダッシュボード</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vartical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            max-width="500px"
        >
        <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                有休等申請
              </v-btn>

            <v-menu
            v-model="menu_month"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                v-model="month"
                label="月を選択してください"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                max-width="100px"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="month"
                v-on:change="change_calendar"
                type="month"
                format="yyyy年MM月"
                value-format="yyyy-MM"
                @input="menu_month = false"
            ></v-date-picker>
            </v-menu>
        </template>              
            <v-card>
              <v-card-title>
                <span class="headline">タイムカード修正</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.date"
                        label="日付"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                    <v-select
                     v-model="editedItem.status"
                      :items="status_list"
                      item-text="label"
                      item-value="value"
                            label="休暇等"
              
                      return-object
                    ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                    <v-menu
                    ref="menu_begin"
                    v-model="menu_begin"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editedItem.begin"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="editedItem.begin"
                        label="出勤"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu_begin"
                        v-model="editedItem.begin"
                        :max="editedItem.end"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menu_begin.save(editedItem.begin)"
                    ></v-time-picker>
                    </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                    <v-menu
                    ref="menu_end"
                    v-model="menu_end"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editedItem.end"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="editedItem.end"
                        label="出勤"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu_end"
                        v-model="editedItem.end"
                        :min="editedItem.begin"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menu_end.save(editedItem.end)"
                    ></v-time-picker>
                    </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                    <v-menu
                    ref="menu_goout"
                    v-model="menu_goout"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editedItem.goout"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="editedItem.goout"
                        label="外出"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu_goout"
                        v-model="editedItem.goout"
                        :min="editedItem.begin"
                        :max="editedItem.return"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menu_goout.save(editedItem.goout)"
                    ></v-time-picker>
                    </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                    <v-menu
                    ref="menu_return"
                    v-model="menu_return"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editedItem.return"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="editedItem.return"
                        label="戻り"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu_return"
                        v-model="editedItem.return"
                        :min="editedItem.goout"
                        :max="editedItem.end"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menu_return.save(editedItem.return)"
                    ></v-time-picker>
                    </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.note"
                        label="備考"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>

          </v-dialog>
 </v-toolbar>
 </template>
     <template v-slot:item.actions="{ item }">
      <v-icon
        medium
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
    </template>
</v-data-table>

<holiday-application ref="holidayDialog"></holiday-application>
</v-app>
</div>

</template>

<script>
//    import * as timecardAPI from '@internal/api/timecardapi.js'
    import * as timecardAPI from '@internal/api/user/timecardapi.js'
    import TimecardEdit from './TimecardEdit'
    import HolidayApplication from './HolidayApplication'
    export default {
        components: {
          HolidayApplication,
          TimecardEdit,
        },
        data() {
            return {
                dialog:false,
                menu_month:false,
                menu_begin:false,
                menu_end:false,
                menu_goout:false,
                menu_return:false,
                status_list:"",
                editedItem: {
                    date: '',
                    status: '',
                    begin: '',
                    end: '',
                    goout:'',
                    return:'',
                    note: '',
                },

                headers:[
                    {
                        text:'修正',
                        sortable:false,
                        value:'actions'
                    },                    
                    {
                        text:'日付',
                        sortable:false,
                        value:'date'
                    },
                    {
                        text:'休暇等',
                        sortable:false,
                        value:'status.label'
                    },
                    {
                        text:'出勤',
                        sortable:false,
                        value:'begin'
                    },
                    {
                        text:'退勤',
                        sortable:false,
                        value:'end'
                    },
                    {
                        text:'外出',
                        sortable:false,
                        value:'goout'
                    },
                    {
                        text:'戻り',
                        sortable:false,
                        value:'return'
                    },
                    {
                        text:'備考',
                        sortable:false,
                        value:'note'
                    },
                ],
                tableData:[
                    {
                        date:'12/1',
                        begin:'8:00',
                        end:'18:00',
                        goout:'',
                        return:''
                    },
                    {
                        date:'12/3',
                        begin:'8:00',
                        end:'18:00',
                        goout:'',
                        return:''
                    },
                    {
                        date:'12/4',
                        begin:'8:00',
                        end:'18:00',
                        goout:'',
                        return:''
                    },
                    {
                        date:'12/8',
                        begin:'8:00',
                        end:'18:00',
                        goout:'',
                        return:''
                    },
                ],
                showEdit:false,
                month: '2021-01', // カレンダーデフォルト値
                table_loading:false,
            };
        },
        mounted() {
            console.log('Component mounted.')
            this.get_month_data();
            this.get_status_list();
        },
        methods: {
            refToParentOpen(){
              this.$refs.holidayDialog.open();
            },
            editItem (item) {
                this.editedIndex = this.tableData.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },
            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            save () {
                if (this.editedIndex > -1) {
                    Object.assign(this.tableData[this.editedIndex], this.editedItem)
                } else {
                    this.tableData.push(this.editedItem)
                }
                this.close()
            },

            get_status_list(){
//              timecardAPI.status_list()
  //            .then(list =>{
    //            this.status_list=list;
      //        })
            },
            get_month_data(){
                this.table_loading=true;
                timecardAPI.daily(this.month).then(temp =>{
                    this.tableData=temp;
                    this.table_loading=false;
                });
            },

            handleClick(index) {
                console.log('click'+index);
                this.showEdit=true;
            },
            change_calendar: function (event) {
                this.get_month_data();
/*                const vm = this
                this.table_loading = true //　カレンダーの値が変わるとボタンはローディング
                

                const timer = setInterval(function(){
                    vm.table_loading = false // ５秒でローディング終了
                    clearInterval(timer)
                }, 5000)
                */
            }
        }
    }
</script>
