<template>
<div id="dialog">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
    >
    <v-card>
      <v-card-text>
        <v-text-field
        label="申請者"
        readonly
        v-model="editedItem.user_name"
      ></v-text-field>
          <v-text-field
            v-model="date"
            label="対象日"
            readonly
          ></v-text-field>
        <v-text-field
        label="種類"
        readonly
        v-model="editedItem.summary"
      ></v-text-field>
        <v-textarea
        label="理由"
        readonly
        v-model="editedItem.note"
      ></v-textarea>
      </v-card-text>  

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          キャンセル
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="auth"
        >
          承認
        </v-btn>
      </v-card-actions>
      </v-card>
      </v-dialog>
      </div>
</template>


<script>
//    import * as timecardAPI from '@internal/api/timecardapi.js'
    import * as holidayAuthAPI from '@internal/api/manager/holidayauthapi.js'
    import HolidayApplication from '@internal/models/HolidayApplication'
  //  import type1 from "./parts/holiday/type1"
    //import type2 from "./parts/holiday/type2"
    //import type3 from "./parts/holiday/type3"

    export default {
        data() {
            return {
                dialog:false,
                editedItem: new HolidayApplication(),

            };
        },
        mounted() {
            console.log('Component mounted.')
        },
        computed:{
          date(){
            if(this.editedItem.date){
              let date=new Date(this.editedItem.date.replace(/-/g,"/"))
                return ('0' + (date.getMonth() + 1) ).slice(-2) + "月" + ('0' + date.getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][date.getDay()] + "）";
              }else{
                return "";
              }
          },
        },
        methods: {
            open(id){
                this.editedItem= new HolidayApplication();
                if(id){
                    holidayAuthAPI.getDatabyId(id)//自分以外のデータを取得するため別関数とする
                    .then(result =>{
                        this.editedItem=result;
                        this.dialog=true
                        }
                    );
                }else{
                    return false;
                }
            },
            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            auth () {
                let message= this.editedItem.user_name + "  " +this.date;
                holidayAuthAPI.auth(this.editedItem)
                .then(result =>{
                    if(result.status){
                        this.$emit("authorized",{type: message});//親に通知してリストを更新させる。
                    }else{
                        
                    }
                })

                
                this.close()
            },

        }
    }
</script>
