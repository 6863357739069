<template>
<div>
        <v-layout>
            <v-checkbox
                v-model="null_flg"
                @change="change_flag"
            >
            </v-checkbox>
            <v-menu
            ref="menu_start"
            v-model="menu_start"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="clock_start"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="clock_start"
                    :disabled = "!null_flg"
                    :label="title"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu_start"
                    v-model="clock_start"
                    full-width
                    format="24hr"
                    @click:minute="$refs.menu_start.save(clock_start);save_start(clock_start)"
                ></v-time-picker>
            </v-menu>
            <v-menu
            ref="menu_end"
            v-model="menu_end"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="clock_end"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="clock_end"
                    :disabled = "!null_flg"
                    :label="title"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                    </template>
                <v-time-picker
                    v-if="menu_end"
                    v-model="clock_end"
                    full-width
                    format="24hr"
                    @click:minute="$refs.menu_end.save(clock_end);save_end(clock_end)"
                ></v-time-picker>
            </v-menu>
        </v-layout>
</div>
</template>

<script>
    export default {
        props:[
          "data",
          "start",
          "end",
          "title"
        ],
        data() {
            return {
                menu_start:false,
                menu_end:false,
                clock_start:"",
                clock_end:"",
                null_flg:false,
            };
        },
        mounted() {
            //clock=this.time
        },
        computed: {
        },
        methods: {
            change_flag(){
                if(!this.null_flg){
                    this.data[this.start]=null;
                    this.data[this.end]=null;
                    this.clock_start="";
                    this.clock_end="";
                }
            },
            save_start(clock){
                this.data[this.start]=clock.substring(0,2)*60+clock.substring(3.5)*1;
            },
            save_end(clock){
                this.data[this.end]=clock.substring(0,2)*60+clock.substring(3.5)*1;
            },
        },
        watch: {
            data(value) {
                let temp=value[this.start];
                let temp2=value[this.end];
                if(temp==null || temp2==null){
                    this.clock_start="";
                    this.clock_end="";
                    this.null_flg=false;
                }else{
                    this.null_flg=true;
                    this.clock_start= ('0' + Math.floor(temp/60)).slice(-2) +  ":" +('0' + temp%60).slice(-2); 
                    this.clock_end= ('0' + Math.floor(temp2/60)).slice(-2) +  ":" +('0' + temp2%60).slice(-2); 
                }
            },
        }
    }
</script>
