export default class TimecardDay{
    constructor(params = {}){
        const {
            date = '',
            name = '',
            absence ='',
            summary ='',
            begin = '',
            end = '',
            goout = '',
            goret = '',
            note = '',
            holiday = '',
            timecard_id = '',
            holiday_id = '',
            short = '',
            early = '',
            work = '',
            late1 = '',
            late2 = '',
            off ='',
        } = params

        this.date = date
        this.name = name
        this.absence = absence
        this.summary = summary
        this.begin = begin
        this.end = end
        this.goout = goout
        this.goret = goret
        this.note = note
        this.holiday = holiday
        this.timecard_id = timecard_id //打刻修正申請ID（あれば）
        this.holiday_id = holiday_id　//休暇申請ID（あれば）
        this.short = short
        this.early = early
        this.work = work
        this.late1 = late1
        this.late2 = late2
        this.off = off
    }
}