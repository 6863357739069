<template>
<div id="user-worktype-dialog">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
    >
            <v-card>
              <v-card-title>
                <span class="headline">勤務型登録</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-card-actions>
                        <v-select
                            v-model="editedItem.work_type_id"
                            :items="work_types"
                            item-text="name"
                            item-value="id"
                            label="勤務型選択"
                            single-line
                        ></v-select>
                      </v-card-actions>
                  </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-menu
                      ref="menu_date"
                      v-model="menu_date"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                          v-model="date"
                          label="適用開始日"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-if="menu_date"
                          v-model="editedItem.date"
                          @input="menu_date = false;"
                          full-width
                      ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-card-actions>
                        <v-select
                            v-model="editedItem.short_calc"
                            :items="true_false"
                            label="遅早計算"
                        ></v-select>
                      </v-card-actions>
                  </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-card-actions>
                        <v-select
                            v-model="editedItem.late_calc"
                            :items="true_false"
                            label="残業計算"
                        ></v-select>
                      </v-card-actions>
                  </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-card-actions>
                        <v-select
                            v-model="editedItem.input_check"
                            :items="true_false"
                            label="未入力チェック"
                        ></v-select>
                      </v-card-actions>
                  </v-col>

                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

</div>

</template>


<script>
    import UserWorkType from '@internal/models/admin/UserWorkType'
    import * as worktypeAPI from '../../../api/admin/worktypeapi.js'

    export default {
        components: {
        },
        data() {
            return {
                dialog:false,
                menu_date:false,
                work_types:[],
                editedItem: new UserWorkType(),
                true_false:[{ text :"有り", value :1},{ text :"無し", value :0}],
                
            };
        },
        mounted() {
        },
        computed:{
          date(){
            if(this.editedItem.date){
              let date=new Date(this.editedItem.date.replace(/-/g,"/"))
                return ('0' + (date.getMonth() + 1) ).slice(-2) + "月" + ('0' + date.getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][date.getDay()] + "）";
              }else{
                return "";
              }
          },
        },
        methods: {
          open(data){
            worktypeAPI.getDatas()
            .then(list =>{
              this.work_types=list;
            })

            if(data){
              this.editedItem= data;
            }else{
              this.editedItem=new UserWorkType();
            }
            this.dialog=true;
          },
          close () {
            this.dialog = false
          },
          save () {
            for(let i=0;i<this.work_types.length;i++){
              if(this.work_types[i].id == this.editedItem.work_type_id){
                this.editedItem.name=this.work_types[i].name;
              }
            }
            this.$emit("saved",{data: this.editedItem});//親に通知してリストを更新させる。              
            this.close()
          },

        }
    }
</script>
