import TimecardDay from './TimecardDay'

export default class TimecardMonth{
    constructor(params = {}){
        const {
            month = '',
            total_day=0,
            work_day=0,
            absence_day=0,
            off_day=0,
            holiday_day=0,
            holiday_hour=0,
            work=0,
            short=0,
            early=0,
            late1=0,
            late2=0,
            holiday1_late1=0,
            holiday1_late2=0,
            holiday2_late1=0,
            holiday2_late2=0,
            off=0,
            daily = '',
            remain_holiday_day,
            remain_holiday_hour,
        } = params

        this.daily = daily.map(day => new TimecardDay(day))
        this.month = month
        this.total_day = total_day
        this.work_day = work_day
        this.absence_day = absence_day
        this.off_day = off_day
        this.holiday_day = holiday_day
        this.holiday_hour = holiday_hour
        this.work = work
        this.short = short
        this.early = early
        this.late1 = late1
        this.late2 = late2
        this.holiday1_late1 = holiday1_late1
        this.holiday1_late2 = holiday1_late2
        this.holiday2_late1 = holiday2_late1
        this.holiday2_late2 = holiday2_late2
        this.off= off
        this.remain_holiday_day = remain_holiday_day
        this.remain_holiday_hour = remain_holiday_hour
        this.display=false
    }
}