import axios from 'axios'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.head.querySelector('meta[name="api-token"]').content;
axios.defaults.baseURL = document.head.querySelector('meta[name="api-root"]').content;

export function getPDF(params,filename){//出力する条件を細かく指定するためAPIを使用
  return axios.get('admin/pdf',{
    responseType: 'blob',
    params: params
})
  .then(response=> {
    let blob= new Blob([response.data], { type: 'application/pdf' })
    let link= document.createElement('a')
    link.href= window.URL.createObjectURL(blob)
    link.download= filename
    link.click()
  })
}

export function getCSV(params,filename){//出力する条件を細かく指定するためAPIを使用
  return axios.get('admin/csv',{
    responseType: 'blob',
    params: params
})
  .then(response=> {
    let blob= new Blob([response.data], { type: 'application/text' })
    let link= document.createElement('a')
    link.href= window.URL.createObjectURL(blob)
    link.download= filename
    link.click()
  })
}

export function getError(params){//出力する条件を細かく指定するためAPIを使用
  return axios.get('admin/error',{
      params: params
    }).then(response =>{
      return response.data;
  })
}

export function CloseMonth(params){//出力する条件を細かく指定するためAPIを使用
  return axios.post('admin/close',params).then(response =>{
    return response.data;
  })
}

export function OpenMonth(params){//出力する条件を細かく指定するためAPIを使用
  return axios.post('admin/open',params).then(response =>{
    return response.data;
  })
}

export function HolidayGrantList(filename){//出力する条件を細かく指定するためAPIを使用
  return axios.get('admin/holiday_grant_list',{
    responseType: 'blob',
})
  .then(response=> {
    let blob= new Blob([response.data], { type: 'application/pdf' })
    let link= document.createElement('a')
    link.href= window.URL.createObjectURL(blob)
    link.download= filename
    link.click()
  })
}

export function HolidayList(filename){//出力する条件を細かく指定するためAPIを使用
  return axios.get('admin/holiday_list',{
    responseType: 'blob',
})
  .then(response=> {
    let blob= new Blob([response.data], { type: 'application/pdf' })
    let link= document.createElement('a')
    link.href= window.URL.createObjectURL(blob)
    link.download= filename
    link.click()
  })
}
