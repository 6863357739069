var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"timecard"}},[_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","centered":"","top":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("\n            閉じる\n          ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n        "+_vm._s(_vm.snackbar_text)+"\n  \n        ")]),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableData,"fixed-header":"","height":"600","hide-default-footer":"","disable-pagination":"","dense":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(('0' + (new Date(item.date.replace(/-/g,"/")).getMonth() + 1) ).slice(-2) + "月" + ('0' + new Date(item.date.replace(/-/g,"/")).getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][new Date(item.date.replace(/-/g,"/")).getDay()] + "）"))])]}},{key:"item.appl_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(('0' + (new Date(item.appl_date.replace(/-/g,"/")).getMonth() + 1) ).slice(-2) + "月" + ('0' + new Date(item.appl_date.replace(/-/g,"/")).getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][new Date(item.appl_date.replace(/-/g,"/")).getDay()] + "）"))])]}},{key:"item.auth1_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.auth1_date ? ('0' + (new Date(item.auth1_date.replace(/-/g,"/")).getMonth() + 1) ).slice(-2) + "月" + ('0' + new Date(item.auth1_date.replace(/-/g,"/")).getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][new Date(item.auth1_date.replace(/-/g,"/")).getDay()] + "）" : ""))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("休暇申請確認")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vartical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('holiday-dialog',{ref:"holidayDialog",on:{"saved":_vm.saved}}),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.openDialog}},[_vm._v("\n                有休等申請\n              ")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.auth1_date)?_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("\n        mdi-pencil\n      ")]):_vm._e(),_vm._v(" "),(!item.delete_appl_id)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.ItemDelete(item)}}},[_vm._v("\n            mdi-delete\n        ")]):_vm._e()]}}])}),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("本当に削除しますか？")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.ItemDeleteConfirm}},[_vm._v("OK")]),_vm._v(" "),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }