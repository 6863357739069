<template>
<div id="dialog">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
    >
    <v-card>
        <v-card-actions>
        <v-select
            v-model="select"
            :items="items"
            item-text="name"
            item-value="type"
            label="選択"
            return-object
            single-line
            @change="change"
        ></v-select>
        </v-card-actions>
        <component v-bind:is="currentType"  @close="close" @save="save" :data="editedItem"></component>
    </v-card>
</v-dialog>

</div>

</template>


<script>
//    import * as timecardAPI from '@internal/api/timecardapi.js'
    import * as holidayAPI from '@internal/api/admin/holidayapi.js'
    import HolidayApplication from '@internal/models/HolidayApplication'
    import type1 from "./parts/holiday/type1"
    import type2 from "./parts/holiday/type2"
    import type3 from "./parts/holiday/type3"

    export default {
        components: {
          type1,
          type2,
          type3,
        },
        data() {
            return {
               select: { name: '欠勤', id: 1,dlg_type:1 },
      items: [
        { name: '欠勤', id: 1, dlg_type:1 },
        { name: '有休', id: 2, dlg_type:2 },
        { name: '特休', id: 3, dlg_type:1 },
        { name: '振  休', id: 4, dlg_type:3 },
      ],
                dialog:false,
                editedItem: new HolidayApplication(),
                user_id:-1,

            };
        },
        mounted() {
            console.log('Component mounted.')
            this.get_status_list();
        },
        computed:{
            currentType(){
                return 'type' + this.select.dlg_type;
            }
        },
        methods: {
            change(){
                this.editedItem.type_id= this.select.id;
            },
            open(user_id,id){
                this.get_status_list();
                this.editedItem= new HolidayApplication();
                this.sync_select();
                this.user_id=user_id;
                if(id){
                    holidayAPI.getDatabyId(id)
                    .then(result =>{
                        this.editedItem=result;
                        this.sync_select();
                        this.dialog=true
                        }
                    );
                }else{
                    this.dialog=true;
                }
            },
            opendate(user_id,date){
                this.get_status_list();
                this.editedItem= new HolidayApplication();
                this.sync_select();
                this.user_id=user_id;
                this.editedItem.date=date;
                this.dialog=true;
            },
            sync_select(){
                this.items.forEach(element => {
                    if(this.editedItem.type_id == element.id){
                        this.select=element;
                    }
                });
            },
            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            save (el) {
                holidayAPI.save(this.user_id,el.data)
                .then(result =>{
                    if(result.data.status){
                        let message= el.data.date + this.select.title;
                        this.$emit("saved",{type: message});//親に通知してリストを更新させる。
                        this.close()
                    }else{
                        alert(result.data.message);                        
                    }
                })                
            },

            get_status_list(){
              holidayAPI.status_list()
              .then(list =>{
                this.items=list;
              })
            },
        }
    }
</script>
