export default class Calender{
    constructor(params = {}){
        const {
            id = '',
            name = '',
        } = params

        this.id = id
        this.name = name
    }
}