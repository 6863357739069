export default class WorkType{
    constructor(params = {}){
        const {
            id = '',
            name = '',
            early_start = 0,
            early_end = 0,
            work_start=0,
            work_end=0,
            break1_start=0,
            break1_end=0,
            break2_start=0,
            break2_end=0,
            break3_start=0,
            break3_end=0,
            late1_start=0,
            late1_end=0,
            late2_start=0,
            late2_end=0,
            half_start=0,
            early_marume_unit=0,
            work_marume_unit=0,
            break_marume_unit=0,
            late_marume_unit=0,
            work_time=0,
        } = params

        this.id = id
        this.name = name
        this.early_start= early_start
        this.early_end = early_end
        this.work_start= work_start
        this.work_end = work_end
        this.break1_start= break1_start
        this.break1_end = break1_end
        this.break2_start= break2_start
        this.break2_end = break2_end
        this.break3_start= break3_start
        this.break3_end = break3_end
        this.late1_start= late1_start
        this.late1_end = late1_end
        this.late2_start= late2_start
        this.late2_end = late2_end
        this.half_start = half_start
        this.early_marume_unit = early_marume_unit
        this.work_marume_unit = work_marume_unit
        this.break_marume_unit = break_marume_unit
        this.late_marume_unit = late_marume_unit
        this.work_time = work_time
    }
}