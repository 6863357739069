export default class Division{
    constructor(params = {}){
        const {
            id = '',
            name = '',
            user_id = '',
        } = params

        this.id = id
        this.name = name
        this.user_id = user_id
    }
}