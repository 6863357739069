<template>
<div id="app">
<v-app>
    <div>
     <v-sheet tile height="3vh" color="grey lighten-3" class="d-flex align-center">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-sheet>
    <v-sheet height="100vh">
        <v-calendar
            color="primary"
            :value="month"
            :events="events"
            @click:date="addHoliday"
        ></v-calendar>
        </v-sheet>
    </div>                
</v-app>
</div>

</template>

<script>
    export default {
        props:[
          "month",
          "events"
        ],
        data() {
            return {
            };
        },
        computed: {
            title() {
                return this.month.substring(0,4) + '年 ' + this.month.substring(5,7) +'月';
            },
        },
        methods: {
            changeHoliday(event) {
                if(event['name']==="法定外休日"){
                    event['name']="法定休日";
                    event['color']='red';
                }else if(event['name']==="法定休日"){
                    event['name']="計画年休";
                    event['color']='blue';
                }else{
                    var index = this.events.findIndex(({start,edit}) => start === event['start'] && edit);
                    this.events.splice(index,1);
                }
            },
            addHoliday({ date }) {
                var index = this.events.findIndex(({start,edit}) => start === date && edit);
                if(index>=0){
                    this.changeHoliday(this.events[index]);
                }else{
                    this.events.push({
                    name: '法定外休日',
                    start:date,
                    end: date,
                    color: 'green',
                    timed: false,
                    edit:true,
                    });
                }
            },
        }
    }
</script>
<style lang="scss">
.lightblue {
    background-color: #99b0f0;
  }
  .lightred {
    background-color: #ffbdf8;
  }
  .lightgreen {
    background-color: #baffc9;
  }
</style>