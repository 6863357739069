export default class UserPaidHoliday{
    constructor(params = {}){
        const {
            id = '',
            days = '',
            date = '',
            duration = '',
            remain_days = '',
            remain_hours = '',
        } = params
        this.id = id
        this.days = days
        this.date = date
        this.duration = duration
        this.remain_days = remain_days
        this.remain_hours = remain_hours
    }
}