<template>
<div id="dialog">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
    >
    <v-card>
        <v-card-actions>
        <v-select
            v-model="select"
            :items="items"
            item-text="name"
            item-value="type"
            label="選択"
            return-object
            single-line
            @change="change"
        ></v-select>
        </v-card-actions>
        <component v-bind:is="currentType"  @close="close" @save="save" :data="editedItem" :saving="saving"></component>
    </v-card>
</v-dialog>

</div>

</template>


<script>
//    import * as timecardAPI from '@internal/api/timecardapi.js'
    import * as holidayAPI from '@internal/api/user/holidayapi.js'
    import HolidayApplication from '@internal/models/HolidayApplication'
    import type1 from "./parts/holiday/type1"
    import type2 from "./parts/holiday/type2"
    import type3 from "./parts/holiday/type3"

    export default {
        components: {
          type1,
          type2,
          type3,
        },
        data() {
            return {
               select: { name: '欠勤', id: 1,dlg_type:1 },
      items: [
        { name: '欠勤', id: 1, dlg_type:1 },
        { name: '有休', id: 2, dlg_type:2 },
        { name: '特休', id: 3, dlg_type:1 },
        { name: '振  休', id: 4, dlg_type:3 },
      ],
                dialog:false,
                saving:false,
                editedItem: new HolidayApplication(),

            };
        },
        mounted() {
            console.log('Component mounted.')
            this.get_status_list();
        },
        computed:{
            currentType(){
                return 'type' + this.select.dlg_type;
            }
        },
        methods: {
            change(){
                this.editedItem.type_id= this.select.id;
            },
            open(id){
                this.editedItem= new HolidayApplication();
  //              this.sync_select();
                if(id){
                    holidayAPI.getDatabyId(id)
                    .then(result =>{
                        this.editedItem=result;
    //                    this.sync_select();
                        this.dialog=true
                        }
                    );
                }else{
                    this.dialog=true;
                }
//                this.get_status_list();
            },
            opendate(date){
                this.editedItem= new HolidayApplication();
//                this.sync_select();
                this.editedItem.date=date;
  //              this.get_status_list();
                this.dialog=true;
            },
            sync_select(){
                    this.items.forEach(element => {
                        if(this.editedItem.type_id == element.id){
                            this.select=element;
                        }
                    });
            },
            close () {
                this.dialog = false
                this.saving=false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            save (el) {
                this.saving=true;
                try{
                holidayAPI.save(el.data)
                .then(result =>{
                    this.saving=false;
                    if(result.data.status){
                        this.close()
                        let message= el.data.date + this.select.title;
                        this.$emit("saved",{type: message});//親に通知してリストを更新させる。
                    }else{
                        alert(result.data.message);                        
                    }
                })
                }catch(error){
                    this.saving=false;
                }               
            },

            get_status_list(){
              holidayAPI.status_list()
              .then(function(list){
                this.items=list;
//                this.sync_select();
              }.bind(this))
            },
        },
        watch: {
            items:  function(newVal, oldVal) {
                        this.sync_select();
    /*                    Object.keys(newVal).forEach( function (v) {
                            if(this.editedItem.type_id == newVal[v].id){
                                this.select=newVal[v];
                            }
                        });
                        */
            },
            editedItem:function(newVal, oldVal) {
                        this.sync_select();
                    }
            
        }
    }
</script>
