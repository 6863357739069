<template>
<div id="app">
<v-app id="timecard">
      <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
      >
        {{ snackbar_text }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            centered
            top
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            閉じる
          </v-btn>
        </template>
      </v-snackbar>

    <v-simple-table dense>
    <template v-slot:top>
        <v-toolbar
            flat
        >
        <v-toolbar-title>部下勤務表</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vartical
        ></v-divider>
        <v-spacer></v-spacer>
        
          <v-select
              v-model="selected_user"
              :items="users"
              item-text="name"
              item-value="id"
              label="部下選択"
              return-object
              single-line
              @change="change_user"
          ></v-select>
          <v-dialog
            v-model="menu_month"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                v-model="month"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                max-width="100px"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="month"
                v-on:change="change_calendar"
                type="month"
                format="yyyy年MM月"
                value-format="yyyy-MM"
                locale="ja-jp"
                @input="menu_month = false"
            ></v-date-picker>
          </v-dialog>

       </v-toolbar>
    </template>
      <template v-slot:default>
        <thead>
          <tr>
            <th class = "text-left"  style="border-width: thin; border-style: solid">
              就業日数
            </th>
            <th class = "text-left"  style="border-width: thin; border-style: solid">
              出勤日数
            </th>
            <th class = "text-left"  style="border-width: thin; border-style: solid">
              欠勤日数
            </th>
            <th class = "text-left"  style="border-width: thin; border-style: solid">
              有休日数(残)
            </th>
            <th class = "text-left"  style="border-width: thin; border-style: solid">
              有休時間数(残)
            </th>
            <th class = "text-left"  style="border-width: thin; border-style: solid">
              就業時間
            </th>
            <th class = "text-left"  style="border-width: thin; border-style: solid">
              遅刻・早退時間
            </th>
            <th class = "text-left"  style="border-width: thin; border-style: solid">
              早出時間
            </th>
            <th class = "text-left"  style="border-width: thin; border-style: solid">
              残業時間
            </th>
            <th class = "text-left"  style="border-width: thin; border-style: solid">
              深夜時間
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td  style="border-width: thin; border-style: solid">{{monthData.total_day}}</td>
            <td  style="border-width: thin; border-style: solid">{{monthData.work_day}}</td>
            <td  style="border-width: thin; border-style: solid">{{monthData.absence_day}}</td>
            <td  style="border-width: thin; border-style: solid">{{monthData.holiday_day}}&ensp;({{monthData.remain_holiday_day}})</td>
            <td  style="border-width: thin; border-style: solid">{{monthData.holiday_hour}}&ensp;({{monthData.remain_holiday_hour}})</td>
            <td  style="border-width: thin; border-style: solid">{{min2hour(monthData.work)}}</td>
            <td  style="border-width: thin; border-style: solid">{{min2hour(monthData.short,false)}}</td>
            <td  style="border-width: thin; border-style: solid">{{min2hour(monthData.early,false)}}</td>
            <td  style="border-width: thin; border-style: solid">{{min2hour(monthData.late1,false)}}</td>
            <td  style="border-width: thin; border-style: solid">{{min2hour(monthData.late2,false)}}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-data-table
    :headers="headers"
    :items="monthData.daily"
    fixed-header
    height="600"
    class="elevation-1"
    hide-default-footer 
    disable-pagination
    :item-class="itemRowBackground"
    >
    <template v-slot:item.date="{ item }">
      <span>{{ ('0' + (new Date(item.date.replace(/-/g,"/")).getMonth() + 1) ).slice(-2) + "月" + ('0' + new Date(item.date.replace(/-/g,"/")).getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][new Date(item.date.replace(/-/g,"/")).getDay()] + "）"}}</span>
    </template>
    <template v-slot:item.begin="{ item }">
      <span>{{min2hour(item.begin)}}</span>
    </template>
    <template v-slot:item.end="{ item }">
      <span>{{min2hour(item.end)}}</span>
    </template>
    <template v-slot:item.goout="{ item }">
      <span>{{min2hour(item.goout)}}</span>
    </template>
    <template v-slot:item.goret="{ item }">
      <span>{{min2hour(item.goret)}}</span>
    </template>
    <template v-slot:item.work="{ item }">
      <span>{{min2hour(item.work,false)}}</span>
    </template>
    <template v-slot:item.short="{ item }">
      <span>{{min2hour(item.short,false)}}</span>
    </template>
    <template v-slot:item.early="{ item }">
      <span>{{min2hour(item.early,false)}}</span>
    </template>
    <template v-slot:item.late1="{ item }">
      <span>{{min2hour(item.late1,false)}}</span>
    </template>
    <template v-slot:item.late2="{ item }">
      <span>{{min2hour(item.late2,false)}}</span>
    </template>

    <template v-slot:item.actions1="{ item }">
      <v-icon
        medium
        class="mr-2"
        @click="openHolidayDialog(item)"
      >
        mdi-pencil
      </v-icon>
        <v-icon v-if="item.holiday_id"
            small
            @click="deleteHoliday(item)"
        >
            mdi-delete
        </v-icon>
    </template>
    <template v-slot:item.actions2="{ item }">
      <v-icon
        medium
        class="mr-2"
        @click="openTimecardDialog(item)"
      >
        mdi-pencil
      </v-icon>
        <v-icon v-if="item.timecard_id"
            small
            @click="deleteTimecard(item)"
        >
            mdi-delete
        </v-icon>
    </template>
</v-data-table>


<holiday-dialog ref="holidayDialog" @saved="saved"></holiday-dialog>
<timecard-dialog ref="timecardDialog" @saved="saved"></timecard-dialog>

<v-dialog v-model="dialogHolidayDelete" max-width="500px">
<v-card>
    <v-card-title class="headline">本当に削除しますか？</v-card-title>
    <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="blue darken-1" text @click="closeHolidayDelete">キャンセル</v-btn>
    <v-btn color="blue darken-1" text @click="deleteHolidayConfirm">ＯＫ</v-btn>
    <v-spacer></v-spacer>
    </v-card-actions>
</v-card>
</v-dialog>

<v-dialog v-model="dialogTimecardDelete" max-width="500px">
<v-card>
    <v-card-title class="headline">本当に削除しますか？</v-card-title>
    <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="blue darken-1" text @click="closeTimecardDelete">キャンセル</v-btn>
    <v-btn color="blue darken-1" text @click="deleteTimecardConfirm">ＯＫ</v-btn>
    <v-spacer></v-spacer>
    </v-card-actions>
</v-card>
</v-dialog>

</v-app>
</div>

</template>

<script>
//    import * as timecardAPI from '@internal/api/timecardapi.js'
    import * as timecardAPI from '@internal/api/manager/timecardapi.js'
    import * as holidayAPI from '@internal/api/manager/holidayapi.js'
//    import TimecardEdit from '../TimecardEdit'
    import TimecardApplication from '@internal/models/TimecardApplication'
    import HolidayDialog from './Dialog/HolidayDialog'
    import TimecardDialog from './Dialog/TimecardDialog'
    import * as userAPI from '@internal/api/manager/userapi.js'

    export default {
        components: {
          HolidayDialog,
          TimecardDialog,
//          HolidayApplication,
//          TimecardEdit,
        },
        data() {
            return {
                dialogHolidayDelete:false,
                dialogTimecardDelete:false,
                deleteItem:null,
                snackbar:false,
                snackbar_text:"",
                snackbar_timeout:1000,
                dialog:false,
                menu_month:false,
                menu_begin:false,
                menu_end:false,
                menu_goout:false,
                menu_return:false,
                status_list:"",
                selected_user:"",
                users: [
                  { name: '', id: -1},
                ],

                headers1:[
                    {
                        text:'休暇申請',
                        sortable:false,
                        value:'actions1'
                    },                    
                    {
                        text:'打刻修正',
                        sortable:false,
                        value:'actions2'
                    },                    
                    {
                        text:'日付',
                        sortable:false,
                        value:'date'
                    },
                    {
                        text:'休暇等',
                        sortable:false,
                        value:'summary'
                    },
                    {
                        text:'出勤',
                        sortable:false,
                        value:'begin'
                    },
                    {
                        text:'退勤',
                        sortable:false,
                        value:'end'
                    },
                    {
                        text:'外出',
                        sortable:false,
                        value:'goout'
                    },
                    {
                        text:'戻り',
                        sortable:false,
                        value:'goret'
                    },
                    {
                        text:'就業時間',
                        sortable:false,
                        value:'work'
                    },
                    {
                        text:'遅刻・早退',
                        sortable:false,
                        value:'short'
                    },
                    {
                        text:'早出',
                        sortable:false,
                        value:'early'
                    },
                    {
                        text:'残業',
                        sortable:false,
                        value:'late1'
                    },
                    {
                        text:'深夜',
                        sortable:false,
                        value:'late2'
                    },
                    {
                        text:'備考',
                        sortable:false,
                        value:'note'
                    },
                ],
                headers2:[
                    {
                        text:'日付',
                        sortable:false,
                        value:'date'
                    },
                    {
                        text:'休暇等',
                        sortable:false,
                        value:'summary'
                    },
                    {
                        text:'出勤',
                        sortable:false,
                        value:'begin'
                    },
                    {
                        text:'退勤',
                        sortable:false,
                        value:'end'
                    },
                    {
                        text:'外出',
                        sortable:false,
                        value:'goout'
                    },
                    {
                        text:'戻り',
                        sortable:false,
                        value:'goret'
                    },
                    {
                        text:'就業時間',
                        sortable:false,
                        value:'work'
                    },
                    {
                        text:'遅刻・早退',
                        sortable:false,
                        value:'short'
                    },
                    {
                        text:'早出',
                        sortable:false,
                        value:'early'
                    },
                    {
                        text:'残業',
                        sortable:false,
                        value:'late1'
                    },
                    {
                        text:'深夜',
                        sortable:false,
                        value:'late2'
                    },
                    {
                        text:'備考',
                        sortable:false,
                        value:'note'
                    },
                ],
                monthData:[
                ],
                showEdit:false,
                month: '2021-01', // カレンダーデフォルト値
                closed_month:'',//締切済月
                close_day:'',
                table_loading:false,
            };
        },
        mounted() {
            this.get_users();

            let that = this;
            timecardAPI.get_closed().then( function(closed){
              that.closed_month=closed.substr(0,4)+ '-' + closed.substr(4,2);
              that.close_day=Number(closed.substr(6,2));
              let today=new Date();
              if(today.getDate() > that.close_day){//締め日の前までは前月とする。
                today.setDate(1); 
                today.setMonth( today.getMonth() + 1);
              }
              that.month=today.getFullYear() + '-' + ('00' + (today.getMonth()+1)).slice(-2);
              that.get_month_data();
            });

        },
        computed:{
          headers(){
            let month=new Date(this.month);
            let closed=new Date(this.closed_month);
            if(month > closed){//なんとかして締切月を取得して締切前と後で表示を変える。
              return this.headers1;
            }else{
              return this.headers2;
            }
          },
        },

        methods: {
            min2hour(min,flg=true){//flg:０を含むか
              if(flg){
                return min>=0 ? (String(Math.floor (min/60)).padStart(2,'0') + ":" + String(min%60).padStart(2,'0') ) : "";
              }else{
                return min>0 ? (String(Math.floor (min/60)).padStart(2,'0') + ":" + String(min%60).padStart(2,'0') ) : "";
              }
            },
            change_user(){
              this.get_month_data();
                //this.= this.select.id;
            },
            get_users(){
                userAPI.getDatas().then(temp =>{
                    this.users=temp;
                });
            },

            openHolidayDialog(item){
              if(item.holiday_id){
                this.$refs.holidayDialog.open(this.selected_user.id,item.holiday_id);
              }else{
                this.$refs.holidayDialog.opendate(this.selected_user.id,item.date);
              }
            },
            openTimecardDialog(item){
              if(item.timecard_id){
                this.$refs.timecardDialog.open(this.selected_user.id,item.timecard_id);
              }else{
                this.$refs.timecardDialog.opendate(this.selected_user.id,item.date);
              }
            },

            itemRowBackground: function (item) {
              return item.holiday ? 'style-2' : 'style-1';
            },
            saved (el) {
                this.snackbar_text=el.type +" 　完了しました";
                this.snackbar=true;
                this.get_month_data();
            },

            get_month_data(){
                this.table_loading=true;
                timecardAPI.monthly(this.selected_user.id,this.month).then(temp =>{
                    this.monthData=temp;
                    this.table_loading=false;
                });
            },

            handleClick(index) {
                console.log('click'+index);
                this.showEdit=true;
            },
            change_calendar: function (event) {
                this.get_month_data();
            },

            closeHolidayDelete(){
                this.dialogHolidayDelete = false;
            },

            deleteHoliday (item) {
                this.deleteItem = item;
                this.dialogHolidayDelete = true
            },
    
            deleteHolidayConfirm () {
                this.dialogHolidayDelete = false;
                holidayAPI.del(this.deleteItem.holiday_id).then(result => {
                    if(result.data.status){
                        this.snackbar_text= "削除処理 　完了しました";
                        this.snackbar=true;

                        this.$nextTick(() => {
                        this.get_month_data(); 
                        });
                    }else{
                        alert(result.data.message);                        
                    }
                });
            },
            closeTimecardDelete(){
                this.dialogTimecardDelete = false;
            },
            deleteTimecard (item) {
                this.deleteItem = item;
                this.dialogTimecardDelete = true
            },
    
            deleteTimecardConfirm () {
                this.dialogTimecardDelete = false;
                timecardAPI.del(this.deleteItem.timecard_id).then(result => {
                    if(result.data.status){
                        this.snackbar_text= "削除処理 　完了しました";
                        this.snackbar=true;

                        this.$nextTick(() => {
                        this.get_month_data(); 
                        });
                    }else{
                        alert(result.data.message);                        
                    }
                });
            },

      }
    }
</script>

<style lang="scss">
.style-1 td{
  background-color: aliceblue;
  background: aliceblue;
}
.style-2 td{
  background-color: pink;
  background: pink;
}
</style>
