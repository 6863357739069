import axios from 'axios'
//import HolidayDay from '../models/HolidayDay'
import HolidayApplication from '@internal/models/HolidayApplication'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.head.querySelector('meta[name="api-token"]').content;
axios.defaults.baseURL = document.head.querySelector('meta[name="api-root"]').content;


export function daily(monthdate){
//    return axios.get('api/user')
  //  .then(res => res.data)
    //.then( data => TimecardDay.map(data))
    return axios.get('holiday-authorization/')
    .then(res => res.data)
    .then( data => data.map(timecard => new HolidayApplication(timecard)))
}


export function auth(payload){
  return axios.post('holiday-authorization/',payload);
}

//export function del(payload){
//  return axios.post('api/holiday-application/delete',payload);
//}

export function getDatabyId(id){
    return axios.get('holiday-application/' + id)
    .then(res => res.data)
    .then( data  => new HolidayApplication(data))
}

//export function status_list(){
//  return axios.get('api/holiday/master')
//  .then(res=>res.data)
//}