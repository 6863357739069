import axios from 'axios'
//import HolidayDay from '../models/HolidayDay'
import HolidayApplication from '@internal/models/HolidayApplication'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.head.querySelector('meta[name="api-token"]').content;
axios.defaults.baseURL = document.head.querySelector('meta[name="api-root"]').content;


export function daily(monthdate){
//    return axios.get('api/user')
  //  .then(res => res.data)
    //.then( data => TimecardDay.map(data))
    return axios.get('holiday-month/' + monthdate)
    .then(res => res.data)
    .then( data => data.map(timecard => new HolidayApplication(timecard)))
}


export function save(user_id,payload){
  return axios.post('manager/holiday-application/'+ user_id ,payload);
}

export function del(id){
  return axios.post('manager/holiday-application/delete/'+id);
}

export function getDatabyId(id){
    return axios.get('manager/holiday-application/' + id)
    .then(res => res.data)
    .then( data  => new HolidayApplication(data))
}

export function status_list(){
  return axios.get('holiday/master')
  .then(res=>res.data)
}