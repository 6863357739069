<template>
<div>
    <v-card-text>
      <v-menu
        v-model="menu_date"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
      <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        label="申請対象日"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
        </template>
        <v-date-picker
          v-model="data.date"
          @input="menu_date = false"
          locale="ja-jp"
          :day-format="date => new Date(date).getDate()"
        ></v-date-picker>
      </v-menu>
    </v-card-text>
    <v-card-text>
      半休等選択
      <v-radio-group v-model="data.full_day" @change="check_hankyu" row>
        <v-radio
          label="一日休"
          :value=1
        ></v-radio>
        <v-radio
          label="時間休"
          :value=0
        ></v-radio>
      </v-radio-group>
      <v-card-text  v-if="data.full_day==0">
        半日単位
        <v-radio-group v-model="data.half_day" row>
          <v-radio
            label="なし"
            :value=0
          ></v-radio>
          <v-radio
            label="午前休"
            :value=1
          ></v-radio>
          <v-radio
            label="午後休"
            :value=2
          ></v-radio>
        </v-radio-group>
        時間単位
        <v-text-field v-model="data.hour" :rules="rules" label="時間を入力"></v-text-field>
        半日以上休む場合は半日休と時間休を同時に取得することも可能です
      </v-card-text>
      <v-card-text>
        <v-textarea
        clearable
        clear-icon="mdi-close-circle"
        label="理由"
        :value="data.note"
        v-model="data.note"
      ></v-textarea>
      </v-card-text>  

    </v-card-text>

    <v-spacer></v-spacer>
    <v-card-actions>
      <v-btn
        color="blue darken-1"
        text
        @click="close"
      >
        キャンセル
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="save"
        :disabled="!save_button"
      >
        申請
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
//有給用

import HolidayApplication from '@internal/models/HolidayApplication'

export default {
        props:[
          "data",
          "saving"
        ],

        data() {
            return {
                //full_day:1,
                menu_date:false,
                rules: [
                  value => {
                    const pattern = /[0-9]/
                    return pattern.test(value) || '数字を入力してください。'
                  },
                ],

            };
        },
        updated(){
//          if(this.data.half_day > 0 || this.data.hours >0){
  //          this.full_day==0;
    //      }else{}
      //    this.check();

        },
        mounted() {
        },
        computed:{
          save_button(){
            if(this.saving){
              return false;
            }
            if(!(this.data.date && this.data.note)){
              return false;
            }
            if(this.data.full_day==1){
              return true;
            }else{
              if(this.data.half_day>0 || this.data.hour>0){
                return true;
              } 
            }
            return false;
          },
          date(){
            if(this.data.date){
              let date=new Date(this.data.date.replace(/-/g,"/"))
                return ('0' + (date.getMonth() + 1) ).slice(-2) + "月" + ('0' + date.getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][date.getDay()] + "）";
              }else{
                return "";
              }
          },
        },
        methods: {
            check_hankyu(){
              if(this.data.full_day == 1){
                this.data.half_day=0;
                this.data.hour=0;
              }
            },
            clear(){
              this.data.full_day=1;
            },
            close () {
              this.clear();
              this.$emit('close');
            },
            save () {
              this.clear();
              this.$emit('save', {data:this.data})
            },
        }
    }
</script>
