import axios from 'axios'
import TimecardMonth from '@internal/models/TimecardMonth'
import TimecardApplication from '@internal/models/TimecardApplication'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.head.querySelector('meta[name="api-token"]').content;
axios.defaults.baseURL = document.head.querySelector('meta[name="api-root"]').content;


export function getApplications(monthdate){
  return axios.get('timecard-application/')
  .then(res => res.data)
  .then( data => data.map(timecard => new TimecardApplication(timecard)))
}

export function daily(monthdate){
//    return axios.get('api/user')
  //  .then(res => res.data)
    //.then( data => TimecardDay.map(data))
    return axios.get('timecard-month/'+ monthdate)
    .then(res => res.data)
    .then( data => new TimecardMonth(data))
}

export function save(payload){
  return axios.post('timecard-application/',payload);
}

export function del(id){
  return axios.post('timecard-application/delete/'+ id);
}

export function getDatabyId(id){
    return axios.get('timecard-application/' + id)
    .then(res => res.data)
    .then( data  => new TimecardApplication(data))
}

export function get_closed(){
  return axios.get('timecard/closed')
    .then(res => String(res.data));
}
