<template>
<div id="app">
<v-app id="timecard">
      <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
      >
        {{ snackbar_text }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            centered
            top
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            閉じる
          </v-btn>
        </template>
    </v-snackbar>

    <v-tabs
      v-model="tab"
      align-with-title
    >
      <v-tabs-slider color="yellow"></v-tabs-slider>

      <v-tab
      >
        一覧
      </v-tab>
      <v-tab
      >
        個別
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
        <v-tab-item
        >
    <v-data-table
    :headers="headers"
    :items="tableData"
    fixed-header
    height="600"
    class="elevation-1"
    hide-default-footer 
    disable-pagination
    :item-class="itemRowBackground"
    >
    <template v-slot:item.date="{ item }">
      <span>{{ ('0' + (new Date(item.date.replace(/-/g,"/")).getMonth() + 1) ).slice(-2) + "月" + ('0' + new Date(item.date.replace(/-/g,"/")).getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][new Date(item.date.replace(/-/g,"/")).getDay()] + "）"}}</span>
    </template>
    <template v-slot:item.begin="{ item }">
      <span>{{ item.begin>=0 ? (String(Math.floor (item.begin/60)).padStart(2, '0') + ":" + String(item.begin%60).padStart(2, '0') ) : ""}}</span>
    </template>
    <template v-slot:item.end="{ item }">
      <span>{{ item.end>=0 ? (String(Math.floor (item.end/60)).padStart(2, '0') + ":" + String(item.end%60).padStart(2, '0') ) : ""}}</span>
    </template>
    <template v-slot:item.goout="{ item }">
      <span>{{ item.goout>=0 ? (String(Math.floor (item.goout/60)).padStart(2, '0') + ":" + String(item.goout%60 ).padStart(2,'0') ) : ""}}</span>
    </template>
    <template v-slot:item.goret="{ item }">
      <span>{{ item.goret>=0 ? (String(Math.floor (item.goret/60)).padStart(2,'0') + ":" + String(item.goret%60).padStart(2,'0') ) : ""}}</span>
    </template>
    <template v-slot:item.work="{ item }">
      <span>{{ item.work>0 ? (String(Math.floor (item.work/60)).padStart(2,'0') + ":" + String(item.work%60).padStart(2,'0') ) : ""}}</span>
    </template>
    <template v-slot:item.short="{ item }">
      <span>{{ item.short>0 ? (String(Math.floor (item.short/60)).padStart(2,'0') + ":" + String(item.short%60).padStart(2,'0') ) : ""}}</span>
    </template>
    <template v-slot:item.early="{ item }">
      <span>{{ item.early>0 ? (String(Math.floor (item.early/60)).padStart(2,'0') + ":" + String(item.early%60).padStart(2,'0') ) : ""}}</span>
    </template>
    <template v-slot:item.late1="{ item }">
      <span>{{ item.late1>0 ? (String(Math.floor (item.late1/60)).padStart(2,'0') + ":" + String(item.late1%60).padStart(2,'0') ) : ""}}</span>
    </template>
    <template v-slot:item.late2="{ item }">
      <span>{{ item.late2>0 ? (String(Math.floor (item.late2/60)).padStart(2,'0') + ":" + String(item.late2%60).padStart(2,'0') ) : ""}}</span>
    </template>

    <template v-slot:top>
        <v-toolbar
            flat
        >
        <v-toolbar-title>部下勤務表</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vartical
        ></v-divider>
        <v-spacer></v-spacer>
          <v-dialog
            v-model="menu_month"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                v-model="date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                max-width="100px"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                v-on:change="change_calendar"
                type="date"
                format="yyyy年MM月dd日"
                value-format="yyyy-MM-dd"
                locale="ja-jp"
                @input="menu_month = false"
            ></v-date-picker>
          </v-dialog>

       </v-toolbar>
    </template>
    <template v-slot:item.actions1="{ item }">
      <v-icon
        medium
        class="mr-2"
        @click="openMonthDialog(item)"
      >
        mdi-table
      </v-icon>
    </template>
</v-data-table>
</v-tab-item>
<timecard-list></timecard-list>
<v-tab-item>
</v-tab-item>
</v-tabs-items>

<timecard-month-dialog ref="timecardMonthDialog"></timecard-month-dialog>

</v-app>
</div>

</template>

<script>
    import * as timecardAPI from '../../api/manager/timecardapi.js'
    import TimecardMonthDialog from './Dialog/TimecardMonthDialog'
    import TimecardList from './TimecardList'

    export default {
        components: {
          TimecardMonthDialog,
          TimecardList,
        },
        data() {
            return {
                snackbar:false,
                snackbar_text:"",
                snackbar_timeout:1000,
                dialog:false,
                menu_month:false,
                status_list:"",
                tab:"",

                headers:[
                    {
                        text:'個別確認',
                        sortable:false,
                        value:'actions1'
                    },                    
                    {
                        text:'名前',
                        sortable:false,
                        value:'name'
                    },
                    {
                        text:'休暇等',
                        sortable:false,
                        value:'summary'
                    },
                    {
                        text:'出勤',
                        sortable:false,
                        value:'begin'
                    },
                    {
                        text:'退勤',
                        sortable:false,
                        value:'end'
                    },
                    {
                        text:'外出',
                        sortable:false,
                        value:'goout'
                    },
                    {
                        text:'戻り',
                        sortable:false,
                        value:'goret'
                    },
                    {
                        text:'就業時間',
                        sortable:false,
                        value:'work'
                    },
                    {
                        text:'遅刻・早退',
                        sortable:false,
                        value:'short'
                    },
                    {
                        text:'早出',
                        sortable:false,
                        value:'early'
                    },
                    {
                        text:'残業',
                        sortable:false,
                        value:'late1'
                    },
                    {
                        text:'深夜',
                        sortable:false,
                        value:'late2'
                    },
                    {
                        text:'備考',
                        sortable:false,
                        value:'note'
                    },
                ],
                tableData:[
                ],
                showEdit:false,
                date: '2021-07-01', // カレンダーデフォルト値
                closed_month:'',//締切済月
                table_loading:false,
            };
        },
        mounted() {
          let today=new Date();
          this.date=today.getFullYear() + '-' + ('00' + (today.getMonth()+1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2);
            let that = this;
            timecardAPI.get_closed().then( function(closed){
              that.closed_month=closed.substr(0,4)+ '-' + closed.substr(4,2);
            });
            this.get_month_data();
        },
        computed:{
/*          headers(){
            let month=new Date(this.month);
            let closed=new Date(this.closed_month);
            if(month >= closed){//なんとかして締切月を取得して締切前と後で表示を変える。
              return this.headers1;
            }else{
              return this.headers2;
            }
          },
          */
        },

        methods: {
            openMonthDialog(item){
              if(item.timecard_id){
                this.$refs.timecardMonthDialog.open(item.timecard_id);
              }else{
                this.$refs.timecardMonthDialog.opendate(item.date);
              }
            },

            itemRowBackground: function (item) {
              return item.holiday ? 'style-2' : 'style-1';
            },
            saved (el) {
                this.snackbar_text=el.type +" 　完了しました";
                this.snackbar=true;
                this.get_month_data();
            },

            get_month_data(){
                this.table_loading=true;
                timecardAPI.daily(this.date).then(temp =>{
                    this.tableData=temp;
                    this.table_loading=false;
                });
            },

            handleClick(index) {
                console.log('click'+index);
                this.showEdit=true;
            },
            change_calendar: function (event) {
                this.get_month_data();
            }
        }
    }
</script>

<style lang="scss">
.style-1 td{
  background-color: aliceblue;
  background: aliceblue;
}
.style-2 td{
  background-color: pink;
  background: pink;
}
</style>
