import Calender from './CalenderUser';
import WorkType from './UserWorkType';
import PaidHoliday from './UserPaidHoliday';

export default class User{
    constructor(params = {}){
        const {
            id = '',
            card_id = '',
            employer_id = '',
            name = '',
            name_kana = '',
            division_id = '',
            division = '',
            calenders = [],
            work_types = [],
            paid_holidays = [],
            hire_date ='',
            hire_date_w = '',
            retire_date = '',
            email = '',
            role = '',
            calender_id = '',
            work_type_id = '',
            calender_name = '',
            work_type_name = '',
            calender_date = '',
            work_type_date = '',
            paid_holiday_days = '',
            paid_holiday_date = '',
            paid_holiday_duration = '',
            late_deduction = '0',
            temp_leave = false,
        } = params

        this.id = id
        this.card_id = card_id
        this.employer_id = employer_id
        this.name = name
        this.name_kana = name_kana
        this.division_id = division_id
        this.division = division
        this.calenders = calenders.map(calender => new Calender(calender))
        this.work_types = work_types.map(work_type => new WorkType(work_type))
        this.paid_holidays = paid_holidays.map(paid_holiday => new PaidHoliday(paid_holiday))
        this.hire_date = hire_date
        this.hire_date_w = hire_date + '(' + hire_date_w + ')'
        this.retire_date = retire_date
        this.email = email
        this.role = role 
        this.calender_id = calender_id
        this.work_type_id = work_type_id
        this.calender_name = calender_name
        this.work_type_name = work_type_name
        this.calender_date = calender_date
        this.work_type_date = work_type_date
        this.paid_holiday_days = paid_holiday_days
        this.paid_holiday_date = paid_holiday_date
        this.paid_holiday_duration = paid_holiday_duration
        this.late_deduction = late_deduction
        this.temp_leave = temp_leave
    }
}