export default class UserWorkType{
    constructor(params = {}){
        const {
            id = '',
            work_type_id = '',
            name = '',
            date = '',
            short_calc = '',
            late_calc = '',
            input_check = '',
        } = params
        this.id = id
        this.work_type_id = work_type_id,
        this.name = name
        this.date = date
        this.short_calc = short_calc
        this.late_calc = late_calc
        this.input_check = input_check
    }
}