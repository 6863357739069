<template>
<div id="app">
<v-app id="timecard">
    <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
      >
        {{ snackbar_text }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            centered
            top
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            閉じる
          </v-btn>
        </template>
    </v-snackbar>

    <v-data-table
    :headers="headers"
    :items="tableData"
    fixed-header
    height="600"
    class="elevation-1"
    hide-default-footer 
    disable-pagination
    dense
    >
    <template v-slot:item.date="{ item }">
      <span>{{ ('0' + (new Date(item.date.replace(/-/g,"/")).getMonth() + 1) ).slice(-2) + "月" + ('0' + new Date(item.date.replace(/-/g,"/")).getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][new Date(item.date.replace(/-/g,"/")).getDay()] + "）"}}</span>
    </template>
    <template v-slot:item.begin="{ item }">
      <span>{{ item.begin ? (item.begin.slice(0,5)) : ""}}</span>
    </template>
    <template v-slot:item.end="{ item }">
      <span>{{ item.end ? (item.end.slice(0,5)) : ""}}</span>
    </template>
    <template v-slot:item.goout="{ item }">
      <span>{{ item.goout ? (item.goout.slice(0,5)) : ""}}</span>
    </template>
    <template v-slot:item.goret="{ item }">
      <span>{{ item.goret ? (item.goret.slice(0,5)) : ""}}</span>
    </template>

    <template v-slot:top>
        <v-toolbar
            flat
        >
        <v-toolbar-title>打刻修正申請確認</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vartical
        ></v-divider>
        <v-spacer></v-spacer>
        <timecard-dialog ref="timecardDialog" @saved="saved"></timecard-dialog>
                    <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="openDialog"
              >
                打刻修正申請
              </v-btn>
      </v-toolbar>
    </template>
     <template v-slot:item.actions="{ item }">
      <v-icon v-if="!item.auth_date"
        medium
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
        <v-icon v-if="!item.auth_date"
            small
            @click="ItemDelete(item)"
        >
            mdi-delete
        </v-icon>
    </template>
</v-data-table>

<v-dialog v-model="dialogDelete" max-width="500px">
<v-card>
    <v-card-title class="headline">本当に削除しますか？</v-card-title>
    <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
    <v-btn color="blue darken-1" text @click="ItemDeleteConfirm">OK</v-btn>
    <v-spacer></v-spacer>
    </v-card-actions>
</v-card>
</v-dialog>

</v-app>
</div>

</template>

<script>
//    import * as timecardAPI from '@internal/api/timecardapi.js'
    import * as timecardAPI from '../../api/user/timecardapi.js'
    import TimecardDialog from './Dialog/TimecardDialog'
    export default {
        components: {
          TimecardDialog,
        },
        data() {
            return {
                snackbar:false,
                snackbar_text:"",
                snackbar_timeout:1000,
                dialog:false,
                dialogDelete:false,
                menu_month:false,
                menu_begin:false,
                menu_end:false,
                menu_goout:false,
                menu_return:false,
                status_list:"",
                editedItem: {
                    date: '',
                    status: '',
                    begin: '',
                    end: '',
                    goout:'',
                    goret:'',
                    note: '',
                },

                headers:[
                    {
                        text:'修正',
                        sortable:false,
                        value:'actions'
                    },                    
                    {
                        text:'日付',
                        sortable:false,
                        value:'date'
                    },
                    {
                        text:'出勤',
                        sortable:false,
                        value:'begin'
                    },
                    {
                        text:'退勤',
                        sortable:false,
                        value:'end'
                    },
                    {
                        text:'外出',
                        sortable:false,
                        value:'goout'
                    },
                    {
                        text:'戻り',
                        sortable:false,
                        value:'goret'
                    },
                    {
                        text:'理由',
                        sortable:false,
                        value:'note'
                    },
                    {
                        text:'申請日',
                        sortable:false,
                        value:'appl_date'
                    },
                    {
                        text:'承認日',
                        sortable:false,
                        value:'auth_date'
                    },
                ],
                tableData:[
                ],
                showEdit:false,
                month: '2021-01', // カレンダーデフォルト値
                table_loading:false,
            };
        },
        mounted() {
            console.log('Component mounted.')
            this.get_month_data();
            //this.get_status_list();
        },
        methods: {
           closeDelete(){
                this.dialogDelete = false;
            },

            ItemDelete (item) {
                this.deleteItem = item;
                this.dialogDelete = true
            },
    
            ItemDeleteConfirm () {
                this.dialogDelete = false;
                timecardAPI.del(this.deleteItem.id).then(result => {
                    if(result.status){
                        this.snackbar_text= "削除処理 　完了しました";
                        this.snackbar=true;

                        this.$nextTick(() => {
                        this.get_month_data(); 
                        });
                    }else{
                        alert(result.data.message);                        
                    }
                });
            },
            saved (el) {
                this.snackbar_text=el.type +" 　完了しました";
                this.snackbar=true;
                this.get_month_data();
            },
            openDialog(){//新規作成
              this.$refs.timecardDialog.open();
            },

            refToParentOpen(){
              this.$refs.holidayDialog.open();
            },
            editItem (item) {//編集
                this.$refs.timecardDialog.open(item["id"]);
            },
            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            save () {
                if (this.editedIndex > -1) {
                    Object.assign(this.tableData[this.editedIndex], this.editedItem)
                } else {
                    this.tableData.push(this.editedItem)
                }
                this.close()
            },

            get_month_data(){
                this.table_loading=true;
                timecardAPI.getApplications(this.month).then(temp =>{
                    this.tableData=temp;
                    this.table_loading=false;
                });
            },

            handleClick(index) {
                console.log('click'+index);
                this.showEdit=true;
            },
            change_calendar: function (event) {
                this.get_month_data();
/*                const vm = this
                this.table_loading = true //　カレンダーの値が変わるとボタンはローディング
                

                const timer = setInterval(function(){
                    vm.table_loading = false // ５秒でローディング終了
                    clearInterval(timer)
                }, 5000)
                */
            }
        }
    }
</script>
