<template>
<div id="app">
<v-app id="timecard">
    <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
      >
        {{ snackbar_text }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            centered
            top
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            閉じる
          </v-btn>
        </template>
    </v-snackbar>
                <v-card>
                <v-card-title>
                  <span class="headline">勤務型登録</span>
                </v-card-title>
                <v-card-actions>
                        <v-select
                        v-model="selected_worktype"
                        :items="worktypes"
                        item-text="name"
                        item-value="id"
                        label="勤務型選択"
                      return-object
                          single-line
                        @change="change_worktype"
                        :disabled="disabled"
                    ></v-select>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                      :disabled="!save_button"
                    >
                      保存
                    </v-btn>
                  </v-card-actions>
  
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="edit_worktype.id"
                        label="ID"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="edit_worktype.name"
                        label="名前"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>                 
                    <v-col
                    >
                    <worktype-part :data="edit_worktype" start="early_start" end="early_end" title="早出"></worktype-part>
                    </v-col>
                    <v-col
                    >
                    <worktype-part :data="edit_worktype" start="work_start" end="work_end" title="勤務"></worktype-part>
                    </v-col>
                  </v-row>
                  <v-row>                 

                    <v-col
                    >
                    <worktype-part :data="edit_worktype" start="break1_start" end="break1_end" title="休憩１"></worktype-part>
                    </v-col>
                    <v-col
                    >
                    <worktype-part :data="edit_worktype" start="break2_start" end="break2_end" title="休憩２"></worktype-part>
                    </v-col>
                  </v-row>
                  <v-row>                 
                    <v-col
                    >
                    <worktype-part :data="edit_worktype" start="break3_start" end="break3_end" title="休憩３"></worktype-part>
                    </v-col>
                  </v-row>
                  <v-row>                 
                    <v-col
                    >
                    <worktype-part :data="edit_worktype" start="late1_start" end="late1_end" title="残業"></worktype-part>
                    </v-col>
                    <v-col
                    >
                    <worktype-part :data="edit_worktype" start="late2_start" end="late2_end" title="深夜"></worktype-part>
                    </v-col>

                  </v-row>
                  <v-row>
                    <v-text-field
                    v-model="edit_worktype.early_marume_unit"
                    label="早出まるめ単位"
                    clearable
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-text-field
                    v-model="edit_worktype.work_marume_unit"
                    label="勤務まるめ単位"
                    clearable
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-text-field
                    v-model="edit_worktype.break_marume_unit"
                    label="休憩まるめ単位"
                    clearable
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-text-field
                    v-model="edit_worktype.late_marume_unit"
                    label="残業まるめ単位"
                    clearable
                    ></v-text-field>
                    <v-menu
                      ref="menu_half"
                      v-model="menu_half"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="clock_half"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="clock_half"
                    label="午後開始時間"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu_half"
                    v-model="clock_half"
                    full-width
                    format="24hr"
                    @click:minute="$refs.menu_half.save(clock_half);save_half(clock_half)"
                ></v-time-picker>
            </v-menu>
                    <v-spacer></v-spacer>

                    <v-text-field
                    v-model="edit_worktype.work_time"
                    label="勤務時間数"
                    clearable
                    ></v-text-field>

                    </v-row>
                </v-container>
              </v-card-text>
  
            </v-card>

</v-app>
</div>

</template>

<script>
    import * as worktypeAPI from '@internal/api/admin/worktypeapi.js'
    import WorktypePart from './parts/WorktypePart'
    import WorkType  from '@internal/models/admin/WorkType.js'

    export default {
        components: {
            WorktypePart,
        },
        data() {
            return {
                snackbar:false,
                snackbar_text:"",
                snackbar_timeout:1000,

                year :2023, // カレンダーデフォルト値
                events: [],
                table_loading:false,
                worktypes: [
                    { name: '欠勤', id: 1 },
                    { name: '有休', id: 2 },
                    { name: '特休', id: 3 },
                    { name: '振  休', id: 4 },
                ],
                early_start:0,
                save_button:false,
                selected_worktype:'',
                edit_worktype:'',
                menu_half:false,
                clock_half:"",
               
              //  disabled:true,

            };
        },
        computed: {
            disabled(){
                return  (this.table_loading==true)
            }
        },

        mounted() {
            this.get_worktypes();
//           this.get_year_data(this.calendar_id,this.year);
        },
        methods: {
            save_half(clock){
                this.edit_worktype.half_start=clock.substring(0,2)*60+clock.substring(3.5)*1;
            },
            get_worktypes(selected_id = null){
                worktypeAPI.getDatas()
                  .then(list =>{
                    this.worktypes=list;
                    if(selected_id){
                      this.worktypes.forEach(element=>{
                        if(selected_id == element.id){
                          this.selected_worktype=element;
                        }
                      });
                    }
//                    this.change_calendar();
                  })
            },
            change_worktype(){
              this.edit_worktype = Object.assign({},this.selected_worktype);
//              this.data= this.worktypes[this.worktype_id];
//              this.data= new WorkType(JSON.stringify(this.worktypes[this.worktype_id]));
//                this.calendar_id= this.select.id;
 //               this.get_year_data(this.calendar_id,this.year);
              
                this.save_button=true;
            },
            month(month) {
                var num=( '0' + month ).slice( -2 );
                return this.year + "-" + num + "-01"; 
            },
            get_year_data(id,year){
                if(id<=0)return;
                this.table_loading=true;
                this.events=[];
                calendarAPI.getHolidays(id,year).then(temp =>{
                    temp.forEach(data => {
                        this.events.push({
                        name: data['type'] ==1 ? '法定休日' :'法定外休日'  ,
                        start:data['date'].substring(0,10),
                        end: data['date'].substring(0,10),
                        color: data['type'] ==1 ? 'red' : 'yellow',
                        timed: false,
                        });
                    });
                    this.table_loading=false;
                });
                
            },
            save () {
                this.save_button=false;
                worktypeAPI.save({'data':this.edit_worktype})
                .then(result =>{
                    if(result.data.status){
                        this.snackbar_text= " 勤務型の保存が完了しました";
                        this.snackbar=true;
                        this.get_worktypes(this.edit_worktype.id);

//                        this.get_year_data(this.calendar_id,this.year);
                    }else{
                        alert(result.data.message);                        
                    }
                }) 
                this.save_button=true;               
            },
        },
        watch: {
            edit_worktype(value) {
                let temp=value.half_start;
                if(temp==null){
                    this.clock_half="";
                }else{
                    this.clock_half= ('0' + Math.floor(temp/60)).slice(-2) +  ":" +('0' + temp%60).slice(-2); 
                }
            },
        }
    }
</script>
