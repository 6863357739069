export default class HolidayApplication{
    constructor(params = {}){
        const {
            id = '',
            type_id =2,
            date = '',
            full_day = 0,
            half_day = 0,
            hour  = 0,
            alt_date = null,
            appl_date = null,
            auth1_date = null,
            user_name = null,
            delete_appl_id = null,
            delete_auth_date = null,
            summary = null,
            note='',
            
        } = params

        this.id = id
        this.type_id = type_id
        this.date = date
        this.full_day = (half_day==0 && hour==0) ? 1 : 0
        this.half_day= half_day
        this.hour = hour
        this.alt_date = alt_date
        this.appl_date = appl_date
        this.auth1_date = auth1_date
        this.summary = summary
        this.delete_appl_id = delete_appl_id
        this.delete_auth_date = delete_auth_date
        this.user_name = user_name
        this.note = note
    }
}