<template>
<div>
      <v-card-actions>
        <v-menu
        v-model="menu_date"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="対象日"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="data.date"
          @input="menu_date = false;"
          @change="check"
          locale="ja-jp"
          :day-format="date => new Date(date).getDate()"
        ></v-date-picker>
      </v-menu>
      </v-card-actions>  
      <v-card-actions>
        <v-textarea
        clearable
        clear-icon="mdi-close-circle"
        label="理由"
        :value="data.note"
        v-model="data.note"
        @input="check"
      ></v-textarea>
      </v-card-actions>  

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          キャンセル
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save"
          :disabled="!save_button"
        >
          申請
        </v-btn>
      </v-card-actions>
      </div>
</template>

<script>
//欠勤、特休用
import * as holidayAPI from '@internal/api/manager/holidayapi.js'
import HolidayApplication from '@internal/models/HolidayApplication'
    export default {
        props:[
          "data",
        ],
        data() {
            return {
                menu_date:false,
                save_button:false,
            };
        },
        mounted() {
        },
        activated () {
        },
        computed:{
          date(){
            if(this.data.date){
              let date=new Date(this.data.date)
                return ('0' + (date.getMonth() + 1) ).slice(-2) + "月" + ('0' + date.getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][date.getDay()] + "）";
              }else{
                return "";
              }
          },
        },
        
        methods: {
            check(){
              if(this.data.date && this.data.note){
                this.save_button=true;
                return;
              }
              this.save_button=false;
            },
            close () {
              this.$emit('close');//コンポーネントの引数のcloseを呼び出し
            },
            save () {
              this.$emit('save', {data:this.data})
            },
        }
    }
</script>
