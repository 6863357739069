import axios from 'axios'
import Calender from '../../models/admin/Calender'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.head.querySelector('meta[name="api-token"]').content;
axios.defaults.baseURL = document.head.querySelector('meta[name="api-root"]').content;

export function getDatas(){
  return axios.get('admin/calender')
  .then(res => res.data)
  .then( data => data.map(user => new Calender(user)))
}

export function getDatabyId(id){
  return axios.get('admin/calender/' + id)
  .then(res => res.data)
  .then( data  => new Calender(data))
}

export function saveHolidays(id,year,events){
  return axios.post('admin/calendarDay/' + id + '/' + year,events);
}


export function getHolidays(id,year){
  return axios.get('admin/calendarDay/' + id + '/' + year)
  .then(res => JSON.parse(res.data))
}
/*
export function saveHolidays(datas){
  return axios.post('admin/calenderEdit',payload);
}
*/
