import axios from 'axios'
import User from '../../models/admin/User'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.head.querySelector('meta[name="api-token"]').content;
axios.defaults.baseURL = document.head.querySelector('meta[name="api-root"]').content;

export function getDatas(){
  return axios.get('admin/user')
  .then(res => res.data)
  .then( data => data.map(user => new User(user)))
}

export function getDatabyId(id){
  return axios.get('admin/user/' + id)
  .then(res => res.data)
  .then( data  => new User(data))
}

export function save(payload){
  return axios.post('admin/user',payload);
}



