<template>
<div id="dialog">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="800px"
    >

  <v-data-table :disable-filtering="true" :hide-default-footer="true" :disable-pagination="true" :disable-sort="true" :headers="headers" :items="items">
    <template v-slot:item.error="{ item }">
        <v-data-table v-if="item.error.warns.length > 0" :hide-default-footer="true" :disable-filtering="true" :disable-pagination="true" :disable-sort="true" :headers="headers2" :items="item.error.warns">
        </v-data-table>
        <v-data-table v-if="item.error.errors.length > 0" :hide-default-footer="true" :disable-filtering="true" :disable-pagination="true" :disable-sort="true" :headers="headers3" :items="item.error.errors">
        </v-data-table>
    </template>
  </v-data-table>
    <v-btn
        color="blue darken-1"
        text
        @click="close"
    >
        閉じる
    </v-btn>

  </v-dialog>

</div>

</template>

<script>
export default {
//  props: ['items'],
  data () {
    return {
        items:[],
      dialog: false,
      headers: [
        {
          text: '従業員名',
          value: 'name',
        },
        {
          text: '検出結果',
          value: 'error',
        },
      ],
      headers2: [
        {
          text: '警告',
          value: 'content',
        },
      ],
      headers3: [
        {
          text: 'エラー',
          value: 'content',
        },
      ],
    }
  },
    methods: {
        open(items){
            this.items=items;
            this.dialog=true;
        },
        close () {
            this.dialog = false
        },
    },
}
</script>