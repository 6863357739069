<template>
<div id="app">
<v-app id="timecard">
      <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
      >
        {{ snackbar_text }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            centered
            top
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            閉じる
          </v-btn>
        </template>
      </v-snackbar>
          <v-dialog
            v-model="menu_month"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="auto"
            max-height="100px"
            style="max-height:100px" 
          >

        <template v-slot:activator="{ on, attrs }">
            <v-text-field
            v-model="month"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            max-width="100px"
            max-height="100px"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="month"
            type="date"
            format="yyyy年MM月"
            value-format="yyyy-MM-dd"
            locale="ja-jp"
            :day-format="(date) => new Date(date).getDate()"
            @input="menu_month = false"
        ></v-date-picker>
      </v-dialog>
      <v-btn
        color="blue"
        centered
        top
        @click="assign_plannedholiday()"
        width="200px"
      >
        計画年休割当
    </v-btn>
    <v-btn
        color="blue"
        centered
        top
        @click="release_plannedholiday()"
        width="200px"
      >
        計画年休割当解除
    </v-btn>
  <error-check-dialog ref="errorCheckDialog"></error-check-dialog>

</v-app>

</div>

</template>

<script>
    import * as timecardAPI from '../../api/admin/timecardapi.js'
    import * as holidayAPI from '../../api/admin/holidayapi.js'
    import TimecardApplication from '@internal/models/TimecardApplication'
    import * as userAPI from '../../api/admin/userapi.js'
    import ErrorCheckDialog from '../admin/Dialog/ErrorCheckDialog'

    export default {
        components: {
          ErrorCheckDialog,
        },
        data() {
            return {
                snackbar:false,
                snackbar_text:"",
                snackbar_timeout:10000,
                dialog:false,
                menu_month:false,
                showEdit:false,
                month: '2021-01-01', // カレンダーデフォルト値
            };
        },
        mounted() {
            let today=new Date();
            this.month=today.getFullYear() + '-' + ('00' + (today.getMonth()+1)).slice(-2) + '-' + ('00' + (today.getDay()+1)).slice(-2);
        },
        computed:{
        },

        methods: {
          openErrorCheckDialog(items){
            this.$refs.errorCheckDialog.open(items);
          },

          assign_plannedholiday () {
            holidayAPI.setPlannedHoliday(this.month)
              .then(result =>{
                  if(result.data.status){
                    this.openErrorCheckDialog(result.data.message);
                  //   this.snackbar_text=result.data.message;
                    // this.snackbar=true;
                  }else{
                      alert(result.data.message);                        
                  }
              })                
          },
          release_plannedholiday () {
            holidayAPI.resetPlannedHoliday(this.month)
              .then(result =>{
                  if(result.data.status){
                    this.openErrorCheckDialog(result.data.message);
                  //   this.snackbar_text=result.data.message;
                    // this.snackbar=true;
                  }else{
                      alert(result.data.message);                        
                  }
              })                
          },

      }
    }
</script>

<style lang="scss">
.style-1 td{
  background-color: aliceblue;
  background: aliceblue;
}
.style-2 td{
  background-color: pink;
  background: pink;
}
</style>
