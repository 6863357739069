export default class TimecardApplication{
    constructor(params = {}){
        const {
            id = '',
            date = '',
            begin = '',
            end = '',
            goout = '',
            goret = '',
            note = '',
            appl_date = '',
            auth1_date = '',
            user_name = '',
            delete_appl_id = null,
            delete_auth_date = null,
        } = params

        this.id = id
        this.date = date
        this.begin = begin && begin >= 0 ? (String(Math.floor (begin / 60)).padStart(2,'0') + ":" + String(begin % 60).padStart(2,'0') ) : ""
        this.begin_flg = begin ? true : false;//空文字なら無効
        this.end = end && end >= 0 ? (String(Math.floor (end / 60)).padStart(2,'0') + ":" + String(end % 60).padStart(2,'0') ) : ""
        this.end_flg = end ? true : false;//空文字なら無効
        this.goout = goout && goout >= 0 ? (String(Math.floor (goout / 60)).padStart(2,'0') + ":" + String(goout % 60).padStart(2,'0') ) : ""
        this.goout_flg = goout ? true : false;//空文字なら無効
        this.goret = goret && goret >= 0 ? (String(Math.floor (goret / 60)).padStart(2,'0') + ":" + String(goret % 60).padStart(2,'0') ) : ""
        this.goret_flg = goret ? true : false;//空文字なら無効
        this.note = note
        this.appl_date = appl_date
        this.auth_date = auth1_date
        this.user_name = user_name
        this.delete_appl_id = delete_appl_id
        this.delete_auth_date = delete_auth_date
    }
}