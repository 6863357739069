export default class CalenderUser{
    constructor(params = {}){
        const {
            id = '',
            calender_id = '',
            name = '',
            date = '',
        } = params
        this.id = id
        this.calender_id = calender_id,
        this.name = name
        this.date = date
    }
}