<template>
<v-dialog
  v-model="dialog"
  max-width="500px"
>
  <v-card>
    <v-card-title>
      <span class="headline">休暇申請</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
          <v-menu
          ref="menu_begin"
          v-model="menu_begin"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="editedItem.begin"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          >
          <template v-slot:activator="{ on, attrs }">
              <v-text-field
              v-model="editedItem.begin"
              label="期間（始）"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
              ></v-text-field>
          </template>
            <v-date-picker
                v-model="editedItem.begin"
                type="day"
                format="yyyy年MM月DD日"
                value-format="yyyy-MM-DD"
                @input="menu_begin = false"
            ></v-date-picker>
          </v-menu>

          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
          <v-select
            v-model="editedItem.status"
            :items="status_list"
            item-text="label"
            item-value="value"
                  label="休暇等"
    
            return-object
          ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
          <v-menu
          ref="menu_begin"
          v-model="menu_begin"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="editedItem.begin"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          >
          <template v-slot:activator="{ on, attrs }">
              <v-text-field
              v-model="editedItem.begin"
              label="出勤"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
              ></v-text-field>
          </template>
          <v-time-picker
              v-if="menu_begin"
              v-model="editedItem.begin"
              :max="editedItem.end"
              full-width
              format="24hr"
              @click:minute="$refs.menu_begin.save(editedItem.begin)"
          ></v-time-picker>
          </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
          <v-menu
          ref="menu_end"
          v-model="menu_end"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="editedItem.end"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          >
          <template v-slot:activator="{ on, attrs }">
              <v-text-field
              v-model="editedItem.end"
              label="出勤"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
              ></v-text-field>
          </template>
          <v-time-picker
              v-if="menu_end"
              v-model="editedItem.end"
              :min="editedItem.begin"
              full-width
              format="24hr"
              @click:minute="$refs.menu_end.save(editedItem.end)"
          ></v-time-picker>
          </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
          <v-menu
          ref="menu_goout"
          v-model="menu_goout"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="editedItem.goout"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          >
          <template v-slot:activator="{ on, attrs }">
              <v-text-field
              v-model="editedItem.goout"
              label="外出"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
              ></v-text-field>
          </template>
          <v-time-picker
              v-if="menu_goout"
              v-model="editedItem.goout"
              :min="editedItem.begin"
              :max="editedItem.return"
              full-width
              format="24hr"
              @click:minute="$refs.menu_goout.save(editedItem.goout)"
          ></v-time-picker>
          </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
          <v-menu
          ref="menu_return"
          v-model="menu_return"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="editedItem.return"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          >
          <template v-slot:activator="{ on, attrs }">
              <v-text-field
              v-model="editedItem.return"
              label="戻り"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
              ></v-text-field>
          </template>
          <v-time-picker
              v-if="menu_return"
              v-model="editedItem.return"
              :min="editedItem.goout"
              :max="editedItem.end"
              full-width
              format="24hr"
              @click:minute="$refs.menu_return.save(editedItem.return)"
          ></v-time-picker>
          </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              v-model="editedItem.note"
              label="備考"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        @click="close"
      >
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>

</v-dialog>


</template>

<script>
//    import * as timecardAPI from '@internal/api/timecardapi.js'
    import * as timecardAPI from '@internal/api/user/timecardapi.js'
    export default {
        props:{
            value:Boolean
        },

        data() {
            return {
                editedItem:"",
                dialog:false,
                menu_month:false,
                menu_begin:false,
                menu_end:false,
                menu_goout:false,
                menu_return:false,
                status_list:"",
                value1: '2021-01', // カレンダーデフォルト値
                table_loading:false,
                tableData:null,
            };
        },
        computed:{
            dialogVisible:{
                get(){
                    return this.value;
                },
                set(value){
                    this.$emit('input',value);
                }
            }
        },
        mounted() {
            console.log('Component mounted.')
//            this.get_month_data();
        },
        methods: {
            open(){
              this.dialog=true;
            },
            close(){

            },
            save(){

            },
            handleClose(done) {
                this.$confirm('Are you sure to close this dialog?')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
            },
            get_month_data(){
                this.table_loading=true;
                timecardAPI.daily(this.value1).then(temp =>{
                    this.tableData=temp;
                    this.table_loading=false;
                });
            },

            change_calendar: function (event) {
 //               this.get_month_data();
            }
        }
    }
</script>
