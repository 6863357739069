<template>
<div id="dialog">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
    >
            <v-card>
              <v-card-title>
                <span class="headline">タイムカード修正</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="date"
                        label="日付"
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                    <v-menu
                    ref="menu_begin"
                    v-model="menu_begin"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editedItem.begin"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="begin"
                        label="出勤"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu_begin"
                        v-model="editedItem.begin"
                        :max="editedItem.end"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menu_begin.save(editedItem.begin)"
                    ></v-time-picker>
                    </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                    <v-menu
                    ref="menu_end"
                    v-model="menu_end"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editedItem.end"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="end"
                        label="退勤"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu_end"
                        v-model="editedItem.end"
                        :min="editedItem.begin"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menu_end.save(editedItem.end)"
                    ></v-time-picker>
                    </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                    <v-menu
                    ref="menu_goout"
                    v-model="menu_goout"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editedItem.goout"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="goout"
                        label="外出"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu_goout"
                        v-model="editedItem.goout"
                        :min="editedItem.begin"
                        :max="editedItem.goret"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menu_goout.save(editedItem.goout)"
                    ></v-time-picker>
                    </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                    <v-menu
                    ref="menu_goret"
                    v-model="menu_goret"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editedItem.goret"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="goret"
                        label="戻り"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu_goret"
                        v-model="editedItem.goret"
                        :min="editedItem.goout"
                        :max="editedItem.end"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menu_goret.save(editedItem.goret)"
                    ></v-time-picker>
                    </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.note"
                        label="備考"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
</v-dialog>

</div>

</template>


<script>
//    import * as timecardAPI from '@internal/api/timecardapi.js'
    import * as timecardAPI from '@internal/api/manager/timecardapi.js'
    import TimecardApplication from '@internal/models/TimecardApplication'

    export default {
        components: {
        },
        data() {
            return {
                menu_begin:false,
                menu_end:false,
                menu_goout:false,
                menu_goret:false,

                dialog:false,
                editedItem: new TimecardApplication(),

            };
        },
        mounted() {
            console.log('Component mounted.')
        },
        computed:{
          date(){
            if(this.editedItem.date){
              let date=new Date(this.editedItem.date.replace(/-/g,"/"))
                return ('0' + (date.getMonth() + 1) ).slice(-2) + "月" + ('0' + date.getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][date.getDay()] + "）";
              }else{
                return "";
              }
          },
          begin(){
            if(this.editedItem.begin){
              return this.editedItem.begin.slice(0,5);
              let time=new Date(this.editedItem.begin)
              return (('0' + time.getHours() ).slice(-2) + "：" + ('0' + time.getMinutes()).slice(-2));
            }else{
              return "";
            }
          },
          end(){
            if(this.editedItem.end){
              return this.editedItem.end.slice(0,5);
              let time=new Date(this.editedItem.end)
              return (('0' + time.getHours() ).slice(-2) + "：" + ('0' + time.getMinutes()).slice(-2));
            }else{
              return "";
            }
          },
          goout(){
            if(this.editedItem.goout){
              return this.editedItem.goout.slice(0,5);
              let time=new Date(this.editedItem.goout)
              return (('0' + time.getHours() ).slice(-2) + "：" + ('0' + time.getMinutes()).slice(-2));
            }else{
              return "";
            }            
          },
          goret(){
            if(this.editedItem.goret){
              return this.editedItem.goret.slice(0,5);
              let time=new Date(this.editedItem.goret)
              return (('0' + time.getHours() ).slice(-2) + "：" + ('0' + time.getMinutes()).slice(-2));
            }else{
              return "";
            }
          },
        },
        methods: {
            open(id){
                this.editedItem= new TimecardApplication();
                if(id){
                    timecardAPI.getDatabyId(id)
                    .then(result =>{
                        this.editedItem=result;
                        this.dialog=true
                        }
                    );
                }else{
                    this.dialog=true;
                }
            },
            opendate(date){
                this.editedItem= new TimecardApplication();
                this.editedItem.date=date;
                this.dialog=true;
            },
            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            save () {
                timecardAPI.save(this.editedItem)
                .then(result =>{
                    if(result.status){
                        let message= "打刻修正申請";
                        this.$emit("saved",{type: message});//親に通知してリストを更新させる。
                    }else{
                        alert(result.data.message);                                                
                    }
                })
                this.close()
            },

        }
    }
</script>
