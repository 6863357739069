<template>
<div id="app">
<v-app id="timecard">
    <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
      >
        {{ snackbar_text }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            centered
            top
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            閉じる
          </v-btn>
        </template>
    </v-snackbar>

    <v-data-table
    :headers="headers"
    :items="tableData"
    fixed-header
    height="600"
    class="elevation-1"
    hide-default-footer 
    disable-pagination
    dense
    >
    <template v-slot:item.date="{ item }">
      <span>{{ ('0' + (new Date(item.date.replace(/-/g,"/")).getMonth() + 1) ).slice(-2) + "月" + ('0' + new Date(item.date.replace(/-/g,"/")).getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][new Date(item.date.replace(/-/g,"/")).getDay()] + "）"}}</span>
    </template>
    <template v-slot:item.appl_date="{ item }">
      <span>{{ ('0' + (new Date(item.appl_date.replace(/-/g,"/")).getMonth() + 1) ).slice(-2) + "月" + ('0' + new Date(item.appl_date.replace(/-/g,"/")).getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][new Date(item.appl_date.replace(/-/g,"/")).getDay()] + "）"}}</span>
    </template>
    <template v-slot:item.auth1_date="{ item }">
      <span>{{ item.auth1_date ? ('0' + (new Date(item.auth1_date.replace(/-/g,"/")).getMonth() + 1) ).slice(-2) + "月" + ('0' + new Date(item.auth1_date.replace(/-/g,"/")).getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][new Date(item.auth1_date.replace(/-/g,"/")).getDay()] + "）" : ""}}</span>
    </template>

    <template v-slot:top>
        <v-toolbar
            flat
        >
        <v-toolbar-title>休暇申請確認</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vartical
        ></v-divider>
        <v-spacer></v-spacer>
        <holiday-dialog ref="holidayDialog" @saved="saved"></holiday-dialog>
                    <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="openDialog"
              >
                有休等申請
              </v-btn>
      </v-toolbar>
    </template>
     <template v-slot:item.actions="{ item }">
      <v-icon v-if="!item.auth1_date"
        medium
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
        <v-icon v-if="!item.delete_appl_id"
            small
            @click="ItemDelete(item)"
        >
            mdi-delete
        </v-icon>
    </template>
</v-data-table>
<v-dialog v-model="dialogDelete" max-width="500px">
<v-card>
    <v-card-title class="headline">本当に削除しますか？</v-card-title>
    <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
    <v-btn color="blue darken-1" text @click="ItemDeleteConfirm">OK</v-btn>
    <v-spacer></v-spacer>
    </v-card-actions>
</v-card>
</v-dialog>

</v-app>
</div>

</template>

<script>
//    import * as timecardAPI from '@internal/api/timecardapi.js'
    import * as holidayAPI from '@internal/api/user/holidayapi.js'
    import HolidayDialog from './Dialog/HolidayDialog'
    export default {
        components: {
          HolidayDialog,
        },
        data() {
            return {
                snackbar:false,
                snackbar_text:"",
                snackbar_timeout:1000,
                dialog:false,
                menu_month:false,
                menu_begin:false,
                menu_end:false,
                menu_goout:false,
                menu_return:false,
                status_list:"",
                deleteItem:"",
                dialogDelete:false,
                editedItem: {
                    date: '',
                    summary: '',
                    appl_date:'',
                    auth1_date:'',
                },

                headers:[
                    {
                        text:'修正、取消',
                        sortable:false,
                        value:'actions'
                    },                    
                    {
                        text:'日付',
                        sortable:false,
                        value:'date'
                    },
                    {
                        text:'休暇種類',
                        sortable:false,
                        value:'summary'
                    },
                    {
                        text:'理由',
                        sortable:false,
                        value:'note'
                    },
                    {
                        text:'申請日',
                        sortable:false,
                        value:'appl_date'
                    },
                    {
                        text:'承認日',
                        sortable:false,
                        value:'auth1_date'
                    },
                ],
                tableData:[],
                showEdit:false,
                month: '2021-01', // カレンダーデフォルト値
                table_loading:false,
            };
        },
        mounted() {
            console.log('Component mounted.')
            this.get_month_data();
        },
        methods: {
            closeDelete(){
                this.dialogDelete = false;
            },

            ItemDelete (item) {
                this.deleteItem = item;
                this.dialogDelete = true
            },
    
            ItemDeleteConfirm () {
                this.dialogDelete = false;
                holidayAPI.del(this.deleteItem.id).then(result => {
                    if(result.data.status){
                        this.snackbar_text= "削除処理 　完了しました";
                        this.snackbar=true;

                        this.$nextTick(() => {
                        this.get_month_data(); 
                        });
                    }else{
                        alert(result.data.message);                        
                    }
                });
            },

            openDialog(){
              this.$refs.holidayDialog.open();
            },

            editItem (item) {
//                this.editedIndex = this.tableData.indexOf(item)
                this.$refs.holidayDialog.open(item["id"]);
  //              this.editedItem = Object.assign({}, item)
    //            this.dialog = true
            },
            saved (el) {
                this.snackbar_text=el.type +" 　完了しました";
                this.snackbar=true;
                this.get_month_data();
            },
            get_month_data(){
                this.table_loading=true;
                holidayAPI.daily(this.month).then(temp =>{
                    this.tableData=temp;
                    this.table_loading=false;
                });
            },
        }
    }
</script>
