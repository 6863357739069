<template>
<div id="app">
<v-app id="timecard">
    <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
      >
        {{ snackbar_text }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            centered
            top
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            閉じる
          </v-btn>
        </template>
    </v-snackbar>

    <v-data-table
    :headers="headers"
    :items="tableData"
    fixed-header
    height="600"
    class="elevation-1"
    hide-default-footer 
    disable-pagination
    dense
    >
    <template v-slot:item.date="{ item }">
      <span>{{ ('0' + (new Date(item.date.replace(/-/g,"/")).getMonth() + 1) ).slice(-2) + "月" + ('0' + new Date(item.date.replace(/-/g,"/")).getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][new Date(item.date.replace(/-/g,"/")).getDay()] + "）"}}</span>
    </template>
    <template v-slot:item.begin="{ item }">
      <span>{{ item.begin ? (item.begin.slice(0,5)) : ""}}</span>
    </template>
    <template v-slot:item.end="{ item }">
      <span>{{ item.end ? (item.end.slice(0,5)) : ""}}</span>
    </template>
    <template v-slot:item.goout="{ item }">
      <span>{{ item.goout ? (item.goout.slice(0,5)) : ""}}</span>
    </template>
    <template v-slot:item.goret="{ item }">
      <span>{{ item.goret ? (item.goret.slice(0,5)) : ""}}</span>
    </template>
    <template v-slot:item.appl_date="{ item }">
      <span>{{ ('0' + (new Date(item.appl_date.replace(/-/g,"/")).getMonth() + 1) ).slice(-2) + "月" + ('0' + new Date(item.appl_date.replace(/-/g,"/")).getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][new Date(item.appl_date.replace(/-/g,"/")).getDay()] + "）"}}</span>
    </template>
    <template v-slot:item.auth_date="{ item }">
      <span>{{item.auth_date ? ('0' + (new Date(item.auth_date.replace(/-/g,"/")).getMonth() + 1) ).slice(-2) + "月" + ('0' + new Date(item.auth_date.replace(/-/g,"/")).getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][new Date(item.auth_date.replace(/-/g,"/")).getDay()] + "）" : ""}}</span>
    </template>

    <template v-slot:top>
        <v-toolbar
            flat
        >
        <v-toolbar-title>打刻修正承認</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vartical
        ></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
     <template v-slot:item.actions="{ item }">
      <v-icon v-if="!item.auth_date || (item.delete_appl_id && !item.delete_auth_date)"
        medium
        class="mr-2"
        @click="authItem(item)"
      >
        mdi-clipboard-check-outline 
      </v-icon>
    </template>
</v-data-table>

<timecard-auth-dialog ref="timecardAuthDialog" @authorized="authorized"></timecard-auth-dialog>

</v-app>
</div>

</template>

<script>
//    import * as timecardAPI from '@internal/api/timecardapi.js'
    import * as timecardAuthAPI from '@internal/api/manager/timecardauthapi.js'
    import TimecardAuthDialog from './Dialog/TimecardAuthDialog'
    export default {
        components: {
          TimecardAuthDialog,
        },
        data() {
            return {
                snackbar:false,
                snackbar_text:"",
                snackbar_timeout:1000,
                dialog:false,
                dialogDelete:false,
                menu_month:false,
                menu_begin:false,
                menu_end:false,
                menu_goout:false,
                menu_return:false,
                status_list:"",
                editedItem: {
                    date: '',
                    status: '',
                    begin: '',
                    end: '',
                    goout:'',
                    goret:'',
                    note: '',
                },

                headers:[
                    {
                        text:'承認',
                        sortable:false,
                        value:'actions'
                    },                    
                    {
                        text:'申請者',
                        sortable:false,
                        value:'user_name'
                    },
                    {
                        text:'日付',
                        sortable:false,
                        value:'date'
                    },                    
                    {
                        text:'出勤',
                        sortable:false,
                        value:'begin'
                    },
                    {
                        text:'退勤',
                        sortable:false,
                        value:'end'
                    },
                    {
                        text:'外出',
                        sortable:false,
                        value:'goout'
                    },
                    {
                        text:'戻り',
                        sortable:false,
                        value:'goret'
                    },
                    {
                        text:'理由',
                        sortable:false,
                        value:'note'
                    },
                    {
                        text:'申請日',
                        sortable:false,
                        value:'appl_date'
                    },
                    {
                        text:'承認日',
                        sortable:false,
                        value:'auth_date'
                    },
                ],
                tableData:[
                ],
                showEdit:false,
                month: '2021-01', // カレンダーデフォルト値
                table_loading:false,
            };
        },
        mounted() {
            console.log('Component mounted.')
            this.get_month_data();
        },
        methods: {
           closeDelete(){
                this.dialogDelete = false;
            },

            authorized (el) {
                this.snackbar_text=el.type +" 　完了しました";
                this.snackbar=true;
                this.get_month_data();
            },
            authItem (item) {//編集
                this.$refs.timecardAuthDialog.open(item["id"]);
            },
            get_month_data(){
                this.table_loading=true;
                timecardAuthAPI.getApplications(this.month).then(temp =>{
                    this.tableData=temp;
                    this.table_loading=false;
                });
            },

        }
    }
</script>
