/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

//import ElementUI from 'element-ui'
//import locale from 'element-ui/lib/locale/lang/ja'
//import 'element-ui/lib/theme-chalk/index.css'
//import 'element-ui/lib/theme-chalk/display.css'
import vuetify from './plugins/vuetify';
//import router from './router.js';
//Vue.use(ElementUI, { locale })
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('timecard-system', require('./components/TimecardSystem.vue').default);
Vue.component('timecard-list', require('./components/user/TimecardList.vue').default);
Vue.component('holiday-list', require('./components/user/HolidayList.vue').default);
Vue.component('application-list', require('./components/user/ApplicationList.vue').default);
Vue.component('holiday-auth', require('./components/manager/HolidayAuth.vue').default);
Vue.component('timecard-auth', require('./components/manager/TimecardAuth.vue').default);
Vue.component('manager-timecard-user-list', require('./components/manager/TimecardUserList.vue').default);
Vue.component('admin-user-management', require('./components/admin/UserManagement.vue').default);
Vue.component('admin-timecard-list', require('./components/admin/TimecardList.vue').default);
Vue.component('admin-timecard-monthly-closing', require('./components/admin/TimecardMonthlyClosing.vue').default);
Vue.component('admin-timecard-data-output', require('./components/admin/TimecardDataOutput.vue').default);
Vue.component('admin-setting-calendar-edit', require('./components/admin/Setting/CalendarEdit.vue').default);
Vue.component('admin-setting-worktype-edit', require('./components/admin/Setting/WorktypeEdit.vue').default);
Vue.component('admin-planned-holiday-assign', require('./components/admin/PlannedHolidayAssign.vue').default);
//Vue.component('app', require('./app.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    vuetify,
    el: '#app',
    //router,
  //  store,
    //template: "<App />"
});
