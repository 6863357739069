<template>
<div id="app">
<v-app id="timecard">
    <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
      >
        {{ snackbar_text }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            centered
            top
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            閉じる
          </v-btn>
        </template>
    </v-snackbar>
    <v-card-actions>
        <v-btn 
            icon
            @click="yearAdd(1)"
            :disabled="disabled"
        >
        <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
            icon
            @click="yearAdd(-1)"
            :disabled="disabled"
        >
        <v-icon>mdi-minus</v-icon>
        </v-btn>
        <v-text-field
            v-model="year"
            label="年選択"
            prepend-icon="mdi-calendar"
            readonly
            :disabled="disabled"
          ></v-text-field>
        <v-spacer></v-spacer>

            <v-select
            v-model="calendar_id"
            :items="calendars"
            item-text="name"
            item-value="id"
            label="カレンダー選択"
            single-line
            @change="change_calendar"
            :disabled="disabled"
        ></v-select>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="save"
          :disabled="!save_button"
        >
          保存
        </v-btn>
      </v-card-actions>


    <table>
        <tr>
            <td>
                <calendar-part :month="month(1)" :events="events"></calendar-part>
            </td>
            <td>
                <calendar-part :month="month(2)" :events="events"></calendar-part>
            </td>
            <td>
                <calendar-part :month="month(3)" :events="events"></calendar-part>
            </td>
        </tr>
        <tr>
            <td>
                <calendar-part :month="month(4)" :events="events"></calendar-part>
            </td>
            <td>
                <calendar-part :month="month(5)" :events="events"></calendar-part>
            </td>
            <td>
                <calendar-part :month="month(6)" :events="events"></calendar-part>
            </td>
        </tr>
        <tr>
            <td>
                <calendar-part :month="month(7)" :events="events"></calendar-part>
            </td>
            <td>
                <calendar-part :month="month(8)" :events="events"></calendar-part>
            </td>
            <td>
                <calendar-part :month="month(9)" :events="events"></calendar-part>
            </td>
        </tr>
        <tr>
            <td>
                <calendar-part :month="month(10)" :events="events"></calendar-part>
            </td>
            <td>
                <calendar-part :month="month(11)" :events="events"></calendar-part>
            </td>
            <td>
                <calendar-part :month="month(12)" :events="events"></calendar-part>
            </td>
        </tr>
        </table>
        

</v-app>
</div>

</template>

<script>
    import * as calendarAPI from '@internal/api/admin/calendarapi.js'
    import CalendarPart from './parts/CalendarPart'
    export default {
        components: {
            CalendarPart,
        },
        data() {
            return {
                snackbar:false,
                snackbar_text:"",
                snackbar_timeout:1000,

                year :2023, // カレンダーデフォルト値
                events: [],
                table_loading:false,
                calendars: [
                    { name: '欠勤', id: 1 },
                    { name: '有休', id: 2 },
                    { name: '特休', id: 3 },
                    { name: '振  休', id: 4 },
                ],
                calendar_id:-1,
                save_button:false,
              //  disabled:true,

            };
        },
        computed: {
            disabled(){
                return  (this.table_loading==true)
            }
        },

        mounted() {
            console.log('Component mounted.');
            let d= new Date();
            this.year = d.getFullYear();
            this.get_calendars();
           this.get_year_data(this.calendar_id,this.year);
        },
        methods: {
            yearAdd(add){
                this.year+=add;
                this.get_year_data(this.calendar_id,this.year);
            },
            get_calendars(){
                calendarAPI.getDatas()
                  .then(list =>{
                    this.calendars=list;
                    this.calendar_id=0;
                    this.change_calendar();
                  })
            },
            change_calendar(){
//                this.calendar_id= this.select.id;
                this.get_year_data(this.calendar_id,this.year);
                this.save_button=true;
            },
            month(month) {
                var num=( '0' + month ).slice( -2 );
                return this.year + "-" + num + "-01"; 
            },
            get_year_data(id,year){
                if(id<0)return;
                this.table_loading=true;
                this.events=[];
                calendarAPI.getHolidays(id,year).then(temp =>{
                    temp.forEach(data => {
                        this.events.push({
                        name: data['type'] ==1 ? '法定休日' : data['type'] ==2 ? '法定外休日' : '計画年休'  ,
                        start:data['date'].substring(0,10),
                        end: data['date'].substring(0,10),
                        color: data['calender_id']!=id ? (data['type'] ==1 ? 'lightred' : data['type'] ==2 ? 'lightgreen' : 'lightblue'):(data['type'] ==1 ? 'red' : data['type'] ==2 ? 'green' : 'blue'),
//                        color:(data['type'] ==1 ? 'red' : data['type'] ==2 ? 'yellow' : 'blue'),
                        timed: false,
                        edit:data['calender_id']==id,
                        });
                    });
                    this.table_loading=false;
                });
                
            },
            save () {
                this.save_button=false;
                var events=this.events.filter((data)=>data.edit);
                calendarAPI.saveHolidays(this.calendar_id,this.year,{'data':events})
                .then(result =>{
                    if(result.data.status){
                        this.snackbar_text= " カレンダーの保存が完了しました";
                        this.snackbar=true;
                        this.get_year_data(this.calendar_id,this.year);
                    }else{
                        this.snackbar_text= result.data.message;
                        this.snackbar=true;
                        alert(result.data.message);                        
                    }
                }) 
                this.save_button=true;               
            },
        },
    }
</script>
