import axios from 'axios'
import TimecardDay from '../../models/TimecardDay'
import TimecardMonth from '../../models/TimecardMonth'
import TimecardApplication from '../../models/TimecardApplication'
import { data } from 'jquery';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + document.head.querySelector('meta[name="api-token"]').content;
axios.defaults.baseURL = document.head.querySelector('meta[name="api-root"]').content;


export function getApplications(monthdate){
  return axios.get('timecard-application/')
  .then(res => {
    if(res.status ==200){
      return data.map(timecard => new TimecardApplication(res.data));
    }else{
      return TimecardApplication();
    }
  }).catch(err => {
    return TimecardApplication();
  });
}

export function daily(id,monthdate){
//    return axios.get('api/user')
  //  .then(res => res.data)
    //.then( data => TimecardDay.map(data))
    return axios.get('admin/timecard-month/'+ id + '/' + monthdate)
    .then(res => {
      if(res.status ==200){
        return new TimecardMonth(res.data);
      }else{
        return TimecardMonth(res.data);
      }
    }).catch(err=>{
      return TimecardMonth(res.data);
    });
//    .then( data => data.map(timecard => new TimecardDay(timecard)))
}

export function save(user_id,payload){
  return axios.post('admin/timecard-application/' + user_id,payload);
}

export function del(id){
  return axios.post('admin/timecard-application/delete/'+ id);
}

export function getDatabyId(id){
    return axios.get('timecard-application/' + id)
    .then(res => res.data)
    .then( data  => new TimecardApplication(data))
}

export function get_closed(){
  return axios.get('timecard/closed')
    .then(res => String(res.data));
}
