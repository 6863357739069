<template>
<div id="app">
<v-app id="admin-user-management">
      <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
      >
        {{ snackbar_text }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            centered
            top
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            閉じる
          </v-btn>
        </template>
    </v-snackbar>

    <v-data-table
    :headers="headers"
    :items="tableData"
    fixed-header
    height="600"
    class="elevation-1"
    hide-default-footer 
    disable-pagination
    :item-class="itemRowBackground"
    >
    <template v-slot:item.date="{ item }">
      <span>{{ ('0' + (new Date(item.date.replace(/-/g,"/")).getMonth() + 1) ).slice(-2) + "月" + ('0' + new Date(item.date.replace(/-/g,"/")).getDate()).slice(-2) + "日（" + [ "日", "月", "火", "水", "木", "金", "土" ][new Date(item.date.replace(/-/g,"/")).getDay()] + "）"}}</span>
    </template>
    <template v-slot:item.begin="{ item }">
      <span>{{ item.begin ? (item.begin.slice(0,5)) : ""}}</span>
    </template>
    <template v-slot:item.end="{ item }">
      <span>{{ item.end ? (item.end.slice(0,5)) : ""}}</span>
    </template>
    <template v-slot:item.goout="{ item }">
      <span>{{ item.goout ? (item.goout.slice(0,5)) : ""}}</span>
    </template>
    <template v-slot:item.goret="{ item }">
      <span>{{ item.goret ? (item.goret.slice(0,5)) : ""}}</span>
    </template>

    <template v-slot:top>
        <v-toolbar
            flat
        >
        <v-toolbar-title>ユーザー一覧</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vartical
        ></v-divider>
        <v-spacer></v-spacer>
        <user-dialog ref="userDialog" @saved="saved"></user-dialog>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="openUserDialog"
          >
            新規追加
          </v-btn>

       </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        medium
        class="mr-2"
        @click="openUserDialog(item)"
      >
        mdi-pencil
      </v-icon>
    </template>
</v-data-table>

<user-dialog ref="userDialog" @saved="saved"></user-dialog>

</v-app>
</div>

</template>

<script>
    import * as userAPI from '../../api/admin/userapi.js'
    import UserDialog from './Dialog/UserDialog'

    export default {
        components: {
          UserDialog,
        },
        data() {
            return {
                snackbar:false,
                snackbar_text:"",
                snackbar_timeout:1000,
                dialog:false,

                headers:[
                    {
                        text:'編集',
                        sortable:false,
                        value:'actions'
                    },                    
                    {
                        text:'社員番号',
                        sortable:true,
                        value:'employer_id'
                    },
                    {
                        text:'名前',
                        sortable:true,
                        value:'name'
                    },
                    {
                        text:'部署名',
                        sortable:true,
                        value:'division'
                    },
                    {
                        text:'カレンダー',
                        sortable:false,
                        value:'calenders[0].name'
                    },
                    {
                        text:'適用開始日',
                        sortable:false,
                        value:'calenders[0].date'
                    },
                    {
                        text:'勤務体制',
                        sortable:false,
                        value:'work_types[0].name'
                    },
                    {
                        text:'遅早計算',
                        sortable:false,
                        value:'work_types[0].short_calc'
                    },
                    {
                        text:'残業計算',
                        sortable:false,
                        value:'work_types[0].late_calc'
                    },
                    {
                        text:'適用開始日',
                        sortable:false,
                        value:'work_types[0].date'
                    },
                    {
                        text:'入社日',
                        sortable:false,
                        value:'hire_date_w'
                    },
                    {
                        text:'退職日',
                        sortable:false,
                        value:'retire_date'
                    },
                    {
                        text:'card_id',
                        sortable:false,
                        value:'card_id'
                    },
                    {
                        text:'E-Mail',
                        sortable:false,
                        value:'email'
                    },
                ],
                tableData:[
                ],
                closed_month:'',//締切済月
                table_loading:false,
            };
        },
        mounted() {
            let that = this;
            this.get_user();
        },
        computed:{
        },

        methods: {
            openUserDialog(item){
              if(item.id){
                this.$refs.userDialog.open(item.id);
              }else{
                this.$refs.userDialog.open();
              }
            },

            itemRowBackground: function (item) {
              return item.retire_date ? 'style-2' : 'style-1';
            },
            saved (el) {
                this.snackbar_text=el.type +" 　完了しました";
                this.snackbar=true;
                this.get_user();
            },

            get_user(){
                this.table_loading=true;
                userAPI.getDatas().then(temp =>{
                    this.tableData=temp;
                    this.table_loading=false;
                });
            },

            handleClick(index) {
                console.log('click'+index);
                this.showEdit=true;
            },
        }
    }
</script>

<style lang="scss">
.style-1 td{
  background-color: aliceblue;
  background: aliceblue;
}
.style-2 td{
  background-color: pink;
  background: pink;
}
</style>
