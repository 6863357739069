<template>
<div id="app">
<v-app id="admin-timecard-data-output">
    <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
      >
        {{ snackbar_text }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            centered
            top
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            閉じる
          </v-btn>
        </template>
    </v-snackbar>

    <v-text-field
    v-model="month"
    prepend-icon="mdi-calendar"
    readonly
    max-width="100px"
    ></v-text-field>
    <v-select
        v-model="selected_user"
        :items="filteredData"
        :item-text="item => item.employer_id + ' : ' + item.name + '('+item.retire_date +')'"
        item-value="id"
        label="社員選択"
        multiple
        return-object
        single-line
    ></v-select>
    <v-checkbox
      v-model="hakenlist"
      label="選択リストを派遣社員のみにする">
    </v-checkbox>
    <v-checkbox
      v-model="ignore_retired"
      label="選択リストから退職者除く">
    </v-checkbox>
    <v-radio-group v-model="radioGroup">
    <v-radio
      key=1
      label="PDF出力"
      value=1
    ></v-radio>
    <v-radio
      key=2
      label="CSV出力"
      value=2
    ></v-radio>
  </v-radio-group>

    <v-spacer></v-spacer>
    <v-btn
      color="blue darken-1"
      text
      @click="MonthlyOutput"
    >
      データ確認
    </v-btn>

    <v-btn
      color="blue darken-1"
      text
      @click="ErrorOutput"
    >
      エラー確認
    </v-btn>

    <v-btn
      color="blue darken-1"
      text
      @click="MonthlyClose"
    >
      締め実行
    </v-btn>
    <v-btn
      color="blue darken-1"
      text
      @click="MonthlyOpen"
    >
      締め解除
    </v-btn>

    <v-btn
      color="blue darken-1"
      text
      @click="HolidayGrantList"
    >
      有給付与者リスト作成
    </v-btn>
    <v-btn
      color="blue darken-1"
      text
      @click="HolidayList"
    >
      有給リスト作成
    </v-btn>
     

<error-check-dialog ref="errorCheckDialog"></error-check-dialog>
</v-app>
</div>

</template>

<script>
    import * as userAPI from '../../api/admin/userapi.js'
    import * as pdfAPI from '../../api/admin/pdfapi.js'
    import * as timecardAPI from '../../api/admin/timecardapi.js'
    import ErrorCheckDialog from '../admin/Dialog/ErrorCheckDialog'
    export default {
        components: {
          ErrorCheckDialog,
        },
        data() {
            return {
                snackbar:false,
                snackbar_text:"",
                snackbar_timeout:1000,
                month: '2021-01', // カレンダーデフォルト値
                table_loading:false,
                selected_user:"",
                hakenlist:false,
                ignore_retired:false,
                users: [
                  { name: '', id: -1},
                ],
                radioGroup:1,
            };
        },
        computed: {
          filteredData(){
            return this.users.filter(item => {
              let flag=true;
              if(this.hakenlist){
                if(item.employer_id  < 900){
                  flag=false;
                }
              }
              if(this.ignore_retired){
                if(item.retire_date !=null){
                  flag=false;
                }
              }
              return flag;
            })
          }
        },
        mounted() {
          this.get_users();
          let that = this;
          timecardAPI.get_closed().then( function(closed){
            let month=new Date(closed.substr(0,4)+ '-' + closed.substr(4,2));
            month.setMonth(month.getMonth() + 1);
            that.month=month.getFullYear() + '-' + ('0' + (month.getMonth()+1)).slice(-2);
//            that.month=closed.substr(0,4)+ '-' + closed.substr(4,2);
            
  //          that.month=closed.substr(0,4)+ '-' + closed.substr(4,2);
 //           that.max_month=that.month;
          });
        },
        methods: {
          HolidayGrantList(){
            pdfAPI.HolidayGrantList('有給付与リスト');
          },
          HolidayList(){
            pdfAPI.HolidayList('有給リスト');
          },
          MonthlyClose(){
            let params={
              month:this.month
            };
            pdfAPI.CloseMonth(params).then(items =>{
              this.openErrorCheckDialog(items);
            })          
          },
          openErrorCheckDialog(items){
            this.$refs.errorCheckDialog.open(items);
          },

          MonthlyOpen(){
            let params={
              month:this.month
            };
            pdfAPI.OpenMonth(params).then(items =>{
              this.openErrorCheckDialog(items);
            })          
          },

          ErrorOutput(){
            let user_Ids=new Array();
            let params={
              month:this.month
            };
            if(this.selected_user){
              this.selected_user.forEach(element => {
                user_Ids.push(element.id);
              });
              params={
                  month:this.month,
                  user: user_Ids
              }
            }
//            pdfAPI.getPDF(params);
            pdfAPI.getError(params).then(items =>{
              this.openErrorCheckDialog(items);
            })
          },
          MonthlyOutput(){
            if(this.radioGroup==1){
              this.MonthlyPdf();
            }else{
              this.MonthlyCsv();
            }
          },
          MonthlyCsv (){

            let user_Ids=new Array();
            let params={
              month:this.month
            };
            if(this.selected_user){
              this.selected_user.forEach(element => {
                user_Ids.push(element.id);
              });
              params={
                  month:this.month,
                  user: user_Ids
              }
            }
//            pdfAPI.getPDF(params);
            pdfAPI.getCSV(params,this.month.replace('-',''));
          },
          MonthlyPdf (){
            let user_Ids=new Array();
            let params={
              month:this.month
            };
            if(this.selected_user){
              this.selected_user.forEach(element => {
                user_Ids.push(element.id);
              });
              params={
                  month:this.month,
                  user: user_Ids
              }
            }
            pdfAPI.getPDF(params,this.month.replace('-',''));
          },
          get_users(){
              userAPI.getDatas().then(temp =>{
                  this.users=temp;
              });
          },


        }
    }
</script>
